// default import
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Chip, Button, capitalize } from '@mui/material'

// helpers
import DefaultAxios from '../../../_utils/DefaultAxios';

// assets
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { generalErrorHandler } from '../../../_utils/Helper';
import ListTransactionModal from './ListTransactionModal';
import { PermissionContext } from '../../../_contexts/PermissionContext';
import Summary, { ISummary } from './Summary';
import DraftClosingTransaction from './DraftClosingTransaction';
import StatementListModal from './StatementListModal';

// interfaces
interface Props {
    id: number
    transactionId: number
    open: boolean
    status: string | null
    code: string
    onClose: (refresh: boolean) => void
}

interface ITransaction_type {
    closing_fee: string;
    komisi_bank: string;
    komisi_developer: string;
    other1: string;
    other2: string;
}

const transactionType: ITransaction_type = {
    closing_fee: "Closing Fee",
    komisi_bank: "Komisi Bank",
    komisi_developer: "Komisi Developer",
    other1: "Komisi Lainnya 1",
    other2: "Komisi Lainnya 2"
}

export interface ILoan {
    loan_agent: IClosingLoan[],
    loan_agent_cobroke: IClosingLoan[]
    agent_name: string
    cobroke_agent_name: string
}
export interface IClosingLoan {
    id: number
    code: string,
    nominal: number,
    is_assigned: number
}

const FormOrder = (props: Props) => {
    const [closingLoan, setClosingLoan] = useState<ILoan>(initialClosingLoan)

    const [summaryData, setSummaryData] = useState<ISummary>(initialSummary)

    const [closingLoanModal, setClosingLoanModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [assignManualModal, setAssignManualModal] = useState(false)

    const permissions = useContext(PermissionContext)
    const [modalTransaction, setModalTransaction] = useState('')

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/closing/summary`, {
                params: {
                    code: props.code
                }
            })
            .then(res => {
                setSummaryData(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                props.onClose(isEdited)
            })
    }


    const fetchClosingLoan = () => {
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/closing-loan/transaction`, {
                params: {
                    id: props.id,
                    transaction_id: props.transactionId
                }
            })
            .then(res => res.data)
            .then(res => {
                setClosingLoan(res)
                setClosingLoanModal(true)
            })
            .catch(err => {
                generalErrorHandler(err)
                // props.onClose()
            })
    }

    useEffect(() => {
        if (props.open) {
            if (props.status && props.status === 'draft') {
                fetchClosingLoan()
            } else {
                fetchData()
            }
        }

        return () => {
            setIsEdited(false)
        }

        // eslint-disable-next-line
    }, [props.status, props.open])

    const renderButtonAction = useMemo(() => {
        if (summaryData.escrow_status) {
            return (
                <Button
                    onClick={() => setModalTransaction(summaryData.escrow_status as string)}
                    style={{ float: 'right', marginLeft: 10 }}
                    variant='contained'
                >
                    {summaryData.escrow_status === 'draft' ? 'Propose' : 'Lihat Transaksi'}
                </Button>
            )
        }
    }, [summaryData.escrow_status])

    const renderListModal = useMemo(() => {
        return (
            <ListTransactionModal
                open={!!modalTransaction}
                id={props.transactionId}
                status={summaryData.escrow_status as string}
                onClose={(refresh: boolean) => {
                    setModalTransaction('')
                    if (refresh) {
                        setIsEdited(true)
                        fetchData()
                    }
                }}
            />
        )

        // eslint-disable-next-line
    }, [modalTransaction, summaryData.escrow_status, props.transactionId])

    const handleCloseSummary = () => {
        setSummaryData(initialSummary)
        props.onClose(isEdited)
    }

    const changeClosingLoan = (loan: IClosingLoan[], type: string) => {
        if (type === 'agent') {
            return setClosingLoan(prev => ({ ...prev, loan_agent: loan }))
        }

        setClosingLoan(prev => ({ ...prev, loan_agent_cobroke: loan }))
    }

    const renderDraftClosingTransaction = useMemo(() => {
        return (
            <DraftClosingTransaction
                open={closingLoanModal}
                transactionId={props.transactionId}
                onClose={() => {
                    props.onClose(isEdited)
                    setClosingLoanModal(false)
                    setClosingLoan(initialClosingLoan)
                }}
                handleSubmitSuccess={() => {
                    setClosingLoan(initialClosingLoan)
                    fetchData()
                }}
                closingLoan={closingLoan}
                changeClosingLoan={changeClosingLoan}
            />
        )

        // eslint-disable-next-line
    }, [closingLoan, closingLoanModal])

    return (
        <>
            {
                !isLoading ?
                    <div>
                        {
                            closingLoanModal ?
                                renderDraftClosingTransaction :
                                null
                        }

                        {
                            summaryData.code ?
                                <>
                                    {renderListModal}
                                    <Dialog
                                        fullWidth={true}
                                        maxWidth={"md"}
                                        open={!!summaryData.code}
                                        onClose={handleCloseSummary}
                                    >
                                        <DialogTitle>
                                            <IconButton
                                                sx={{ mr: 1 }}
                                                onClick={() => props.onClose(isEdited)}
                                            >
                                                <ArrowBackIcon />
                                            </IconButton>
                                            Form {transactionType[summaryData.type as keyof ITransaction_type]}
                                            {
                                                summaryData.escrow_status ?
                                                    <Chip
                                                        sx={{ ml: 1 }}
                                                        label={capitalize(summaryData.escrow_status)}
                                                        variant='outlined'
                                                        color='primary'
                                                        size='small'
                                                    /> : null

                                            }

                                            {
                                                permissions['closing-transaction.propose'] ?
                                                    renderButtonAction
                                                    : null
                                            }
                                            {
                                                permissions['closing-transaction.assign-manual'] && summaryData.escrow_status === 'draft' ?
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => setAssignManualModal(true)}
                                                        style={{ float: 'right', order: 1 }}
                                                    >
                                                        Assign Manual
                                                    </Button> :
                                                    null
                                            }
                                        </DialogTitle>
                                        <DialogContent dividers>
                                            <Summary
                                                summaryData={summaryData}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </>
                                : null
                        }
                    </div> : null
            }

            <StatementListModal
                id={props.transactionId}
                open={assignManualModal}
                onClose={(refresh: boolean) => {
                    setAssignManualModal(false)

                    if (refresh) {
                        setIsEdited(true)
                        fetchData()
                    }
                }}
            />
        </>
    )
}

const initialClosingLoan = {
    loan_agent: [],
    loan_agent_cobroke: [],
    agent_name: "",
    cobroke_agent_name: ""
}

export const initialSummary = {
    type: "",
    is_cobroke: 0,
    code: "",
    escrow_status: 'draft',
    unit_information: "",
    full_name_agent: "",
    agent_bank_information: "",
    cobroke_agent_bank_information: "",
    client_name: "",
    transaction_amount: 0,
    transaction_amount_exclude_tax: 0,
    nominal: 0,
    dpp_amount: 0,
    ppn_amount: 0,
    pph23_amount: 0,
    gross_commission: 0,
    office_commission_amount: 0,
    office_commission_percentage: 0,
    agent_pph21_amount: 0,
    borrowed_commission: 0,
    total_transfer_office: 0,
    total_transfer_advisor: 0,
    total_transfer_sum: 0,
    total_credit: null,
    // borrowed_commission_amount: 0,
    agent_commission_amount: 0,
    full_name_agent_cobroke: "",
    agent_commission_percentage: 0,
    cobroke_commission_amount: 0,
    cobroke_commission_percentage: 0,
    cobroke_pph21_amount: 0,
    telesales_commission_amount: 0,
    // cobroke_borrowed_commission_amount: 0,
    total_transfer_advisor_2: 0,
    office_percentage_compare_from_gross: 0,
    agent_percentage_compare_from_gross: 0,
    cobroke_percentage_compare_from_gross: 0,
    paid_loan_list: [],
    cobroke_paid_loan_list: [],
}

export default FormOrder