import React, { useState, useContext } from 'react'

/**
 * Components
 */
import { Button, IconButton, Switch, TextField, Tooltip, Select, MenuItem } from '@mui/material';
import DataTable from '../../_components/_dataTable/DataTable';
import AgentForm from './AgentForm';
import LoadingScreen from '../../_components/LoadingScreen';
import UserPlacementModal from '../_user/_components/UserPlacementModal';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icons
 */
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryIcon from '@mui/icons-material/History';
import CalendarIcon from '@mui/icons-material/CalendarMonth';

/**
 * Data
 */
import UserLog from '../_user/_components/UserLog';
import AgentLeadScheduleList from './AgentLeadScheduleList';
import { generateStyle } from '../../_utils/DefaultStyles';

interface AgentListProps {
    isApp?: boolean
}

const PunishmentLevels = [
    {
        value: '0',
        label: 'None - No Limit'
    },
    {
        value: '1',
        label: 'Level 1 - 4 Leads / day'
    },
    {
        value: '2',
        label: 'Level 2 - 2 Leads / day'
    },
    {
        value: '3',
        label: 'Level 3 - No Leads (Paused)'
    },
]

const AgentList = (props: AgentListProps) => {
    const permissions = useContext(PermissionContext);
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [userId, setUserId] = useState('');
    const [logUserId, setLogUserId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalScheduleOpen, setModalScheduleOpen] = useState(false);
    const [modalLogOpen, setModalLogOpen] = useState(false);
    const [defaultLimit, setDefaultLimit] = useState(0)

    const [userPlacementValue, setUserPlacementValue] = useState({
        id: "",
        name: ""
    });

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = (fetch: boolean) => {
        setUserId('');
        setModalOpen(false);
        if (fetch) {
            setReDrawDatatable(new Date().getTime());
        }
    }

    const handleModalScheduleOpen = () => setModalScheduleOpen(true);
    const handleModalScheduleClose = (fetch: boolean) => {
        setUserId('');
        setModalScheduleOpen(false);
        if (fetch) {
            setReDrawDatatable(new Date().getTime());
        }
    }

    const handleModalLogOpen = () => setModalLogOpen(true);
    const handleModalLogClose = () => {
        setLogUserId('');
        setModalLogOpen(false);
    }

    const handleEdit = (id: string) => {
        setUserId(id);
        handleModalOpen();
    }

    const handleEditSchedule = (id: string) => {
        setUserId(id);
        handleModalScheduleOpen();
    }

    const handleResetPassword = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin me-reset password agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/reset-password`, { id })
                        .then((res) => {
                            renderToastSuccess("Berhasil mereset password")
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchPartTime = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status part time?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/toggle-part-time`, { id })
                        .then(() => {
                            renderToastSuccess("Berhasil mengganti status part time")
                            setReDrawDatatable(reDrawDatatable + 1);
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/toggle-active`, { id })
                        .then(() => {
                            renderToastSuccess("Berhasil mengganti status")
                            setReDrawDatatable(reDrawDatatable + 1)
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchPause = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status pause?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/toggle-pause`, { id })
                        .then(() => {
                            renderToastSuccess("Berhasil mengganti pause")
                            setReDrawDatatable(reDrawDatatable + 1)
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchAutoCreateLeads = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status Auto Create Leads?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/toggle-auto-create-leads`, { id })
                        .then(() => {
                            renderToastSuccess("Berhasil mengganti Auto Create Leads")
                            setReDrawDatatable(reDrawDatatable + 1)
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const switchOption = (type: 'is_excluded_from_new_placement' | 'is_exclude_get_overflow_leads' | 'is_manual_level_setting' | 'is_manual_punishment', id: string) => {
        let message = 'UNDEFINED';
        let endpoint = 'UNDEFINED';
        switch (type) {
            case 'is_excluded_from_new_placement':
                message = 'Exclude Global Placement';
                endpoint = '/toggle-exclude-global-placement';
                break;
            case 'is_exclude_get_overflow_leads':
                message = 'Exclude Overflow';
                endpoint = '/toggle-exclude-overflow';
                break;
            case 'is_manual_level_setting':
                message = 'Manual Level Setting';
                endpoint = '/toggle-manual-level-setting';
                break;
            case 'is_manual_punishment':
                message = 'Manual Punishment';
                endpoint = '/toggle-manual-punishment';
                break;
            default:
                break;
        }

        renderWarningButton(`Apakah anda yakin ingin mengganti ${message}?`)
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user${endpoint}`, { id })
                        .then(() => {
                            renderToastSuccess(`Berhasil mengganti ${message}`)
                            setReDrawDatatable(reDrawDatatable + 1)
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleLog = (id: string) => {
        setLogUserId(id);
        handleModalLogOpen();
    }

    const handleChangeLimitPerday = (limit_leads_per_day: number, id: string) => {
        renderWarningButton('Apakah anda yakin akan mengubah limit leads per day pada agent ini ?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/user/change-limit-per-day`, {
                        id,
                        limit_leads_per_day
                    })
                        .then(res => res.data)
                        .then(res => {
                            if (res) {
                                renderToastSuccess('Limit berhasil diganti')
                            }
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => {
                            setIsLoading(false)
                            setReDrawDatatable(new Date().getTime())
                        })
                }
            })
    }

    const onPunishmentChange = (value: string, id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengubah punishment level agent ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/user/change-punishment-level`, {
                        id,
                        punishment_level: value,
                    })
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess('Berhasil mengubah level punishment')
                            setReDrawDatatable(new Date().getTime());
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            // render: (data: string, row: any) => {
            //     return (
            //         <span style={{ color: +row.is_paused_by_bot === 1 ? 'black' : 'black' }}>
            //             {data}
            //         </span>
            //     )
            // }
        },
        {
            name: 'wa_container_name',
            label: 'WA Container Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'full_name',
            label: 'Full Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'group_name',
            label: 'Group Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'level',
            label: 'Level',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'junior',
                    value: 'Junior'
                },
                {
                    key: 'mid',
                    value: 'Mid'
                },
                {
                    key: 'senior',
                    value: 'Senior'
                },
            ],
            filterable: true,
            render: (data: any) => {
                return data ? data.charAt(0).toUpperCase() + data.slice(1) : '-';
            }
        },
        ...(
            permissions['user.change-limit-leads'] ? [{
                name: 'limit_leads_per_day',
                label: 'Leads per day',
                type: 'string',
                defaultOption: '',
                options: [
                    {
                        key: '',
                        value: 'All'
                    }
                ],
                filterable: true,
                render: (data: any, row: any) => {
                    return (
                        permissions['user.change-limit-leads'] ?
                            <Select
                                value={data}
                                onChange={(e) => handleChangeLimitPerday(e.target.value, row.id)}
                            >
                                <MenuItem value={0}>Default ({defaultLimit} per day)</MenuItem>
                                {
                                    (() => {
                                        let list = []
                                        for (let limit = 1; limit <= 10; limit++) {
                                            list.push(<MenuItem value={limit}>{limit} Per day</MenuItem>)
                                        }
                                        return list
                                    })()
                                }
                            </Select> :
                            <span>{data ? data : defaultLimit} Per day</span>
                    )
                }
            }] : []),
        {
            name: 'punishment_level',
            label: 'Punishment Level',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...PunishmentLevels.map(punishment => ({
                    key: punishment.value,
                    value: punishment.label,
                }))
            ],
            filterable: true,
            columnStyle: {
                minWidth: 250,
            },
            render: (data: number, row: any) => (
                permissions['user.change-punishment-level'] ?
                    <TextField
                        name="punishment_level"
                        select
                        value={data.toString()}
                        fullWidth
                        onChange={e => onPunishmentChange(e.target.value, row.id)}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            native: true,
                        }}
                        size="small"
                        variant="outlined"
                    >
                        {
                            PunishmentLevels.map(punishment =>
                                <option key={punishment.value} value={punishment.value}>{punishment.label}</option>
                            )
                        }
                    </TextField>
                    :
                    PunishmentLevels.filter(punishment => punishment.value === data.toString())[0]?.label || '-'
            )
        },
        // {
        //     name: 'leads_percentage',
        //     label: 'Leads Percentage',
        //     type: 'string',
        //     filterable: true
        // },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'is_part_time',
            label: 'Part Time',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchPartTime(row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'In-Active'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
                // return data === 1 ? 'Active' : 'In-Active'
            }
        },
        {
            name: 'is_paused',
            label: 'Paused',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Paused'
                },
                {
                    key: '0',
                    value: 'Not Paused'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchPause(row.id)}
                    color="secondary"
                />
                // return data === 1 ? 'Paused' : 'Not Paused'
            }
        },
        {
            name: 'is_auto_create_leads',
            label: 'Auto Create Leads',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Enabled'
                },
                {
                    key: '0',
                    value: 'Disabled'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    permissions['user.toggle-auto-create-leads'] ?
                        <Switch
                            checked={+data === 1}
                            onChange={() => switchAutoCreateLeads(row.id)}
                            color="secondary"
                        />
                        : data === 1 ? 'Enabled' : 'Disabled'
                )
                // return data === 1 ? 'Paused' : 'Not Paused'
            }
        },
        {
            name: 'is_excluded_from_new_placement',
            label: 'Exclude Global Placement',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Excluded'
                },
                {
                    key: '0',
                    value: 'Not Excluded'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchOption('is_excluded_from_new_placement', row.id)}
                    color="secondary"
                />
            }
        },
        // {
        //     name: 'is_exclude_get_overflow_leads',
        //     label: 'Exclude Overflow',
        //     type: 'string',
        //     defaultOption: '',
        //     options: [
        //         {
        //             key: '',
        //             value: 'All'
        //         },
        //         {
        //             key: '1',
        //             value: 'Excluded'
        //         },
        //         {
        //             key: '0',
        //             value: 'Not Excluded'
        //         }
        //     ],
        //     filterable: true,
        //     render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
        //         return <Switch
        //             checked={+data === 1}
        //             onChange={() => switchOption('is_exclude_get_overflow_leads', row.id)}
        //             color="secondary"
        //         />
        //     }
        // },
        {
            name: 'is_manual_punishment',
            label: 'Punishment Manual',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <>
                        {
                            permissions['user.toggle-manual-punishment'] ?
                                <Switch
                                    checked={+data === 1}
                                    onChange={() => switchOption('is_manual_punishment', row.id)}
                                    color="secondary"
                                /> :
                                <span>{data === 1 ? 'Yes' : 'No'}</span>
                        }
                    </>
                )
            }
        },
        {
            name: 'is_manual_level_setting',
            label: 'Manual Level Setting',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Automatic'
                },
                {
                    key: '0',
                    value: 'Manual'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchOption('is_manual_level_setting', row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {
                            permissions['user.manage'] &&
                            <Tooltip title="Edit Schedule">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleEditSchedule(row.id)}
                                >
                                    <CalendarIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            permissions['cluster.manage-member'] &&
                            <Tooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setUserPlacementValue({
                                        id: row.id,
                                        name: row.name
                                    })}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Reset Password">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleResetPassword(row.id)}
                            >
                                <RotateLeftIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Agent Log">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleLog(row.id)}
                            >
                                <HistoryIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>

                    </div>
                );
            }
        },
    ];

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <UserPlacementModal
                isOpen={userPlacementValue.id !== "" ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setUserPlacementValue({
                        id: "",
                        name: ""
                    });
                }}
                user_name={userPlacementValue.name}
                user_id={userPlacementValue.id}
            />
            <AgentForm
                open={modalOpen}
                onClose={handleModalClose}
                userId={userId}
            />

            <AgentLeadScheduleList
                open={modalScheduleOpen}
                onClose={handleModalScheduleClose}
                userId={userId}
            />

            <UserLog
                open={modalLogOpen}
                onClose={handleModalLogClose}
                userId={logUserId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New Agent
            </Button>
            <DataTable
                url={`${process.env.REACT_APP_API_URL}/user/agent-admin`}
                columns={columns}
                reDraw={reDrawDatatable}
                isHeaderSticky={props.isApp}
                tableContainerMaxHeight={props.isApp ? 'calc(100vh - 180px)' : undefined}
                responseCallback={(data: any) => setDefaultLimit(data.default_limit_per_day)}
            />
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "Agent_List"
)

export default AgentList
