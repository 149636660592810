import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

/**
 * Components
 */
import { MenuItem, TextField, Theme, useMediaQuery } from '@mui/material'
import DataNA from '../../_components/DataNA'
import LoadingCard from '../../_components/_cards/LoadingCard'
import FunnelReportCard from './_components/FunnelReportCard'
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker'
import FunnelReportClosingList from './_components/FunnelReportClosingList'

/**
 * Utils
 */
import moment from 'moment'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import { PermissionContext } from "../../_contexts/PermissionContext";
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { generateStyle } from '../../_utils/DefaultStyles'

interface IState {
    startDate: DateRange
    endDate: DateRange
}

export type IReportCluster = {
    label: string
    amount: number
}

export type IReportFunnel = {
    leads_report: number
    leads_transferred_report: number
    closings_report: number
    showings_plan: number
    showings_done_report: number
    osa_score: number
    response_time_score: number
    closing_utj_refund_report: number
    closing_utj_non_refund_report: number
    closing_agreed_sum: number
    closing_agreed_count: number
}

export interface FunnelReportItem {
    id: string
    name: string
    clusters: IReportFunnel
    total_response_time?: number
    avatar_url: string
}

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/M/Y')
}

const FunnelReport = () => {
    const { Root, classes } = useStyles()
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const ListRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useState<IState>({
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'days'),
    })

    const permissions = useContext(PermissionContext);

    const [isFirstInit, setIsFirstInit] = useState(true)
    const [list, setList] = useState<FunnelReportItem[]>([])
    const [sort, setSort] = useState('default');
    const [groupId, setGroupId] = useState('default');
    const [groups, setGroups] = useState<{ id: number, name: string }[]>([]);
    const params: any = useParams()

    const [selectedClosing, setSelectedClosing] = useState<FunnelReportItem | null>(null)
    const [selectedType, setSelectedType] = useState('')

    const [flags, setFlags] = useState({
        isInit: true,
        hasMore: true,
        isLoading: true,
        isRefreshing: false,
        isChanged: false,
    })

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        setState(prev => ({
            ...prev,
            ...dates
        }));
    }

    useEffect(() => {
        if (params.group_id) {
            setGroupId(params.group_id)
        }
    }, [params.group_id])

    useEffect(() => {
        fetchData(true)
        fetchGroups()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (flags.isRefreshing) fetchData(true)
        // eslint-disable-next-line
    }, [flags.isRefreshing])

    useEffect(() => {
        if (!isFirstInit) {
            fetchData(true)
        }
        // eslint-disable-next-line
    }, [sort]);

    useEffect(() => {
        if (!isFirstInit) {
            fetchData(true)
        }
        // eslint-disable-next-line
    }, [groupId]);

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                setGroups(data)
            })
            .catch(generalErrorHandler)
    }

    const fetchData = (isFiltered?: boolean) => {
        let initialGroupId: null | string = null

        setFlags(prev => ({
            ...prev,
            isLoading: true,
        }))

        if (isFiltered) {
            ListRef.current?.scrollTo({ top: 0 })
            setList([])
        }

        if (isFirstInit && !params.group_id) {
            const fetchGroupId = localStorage.getItem(`selected_group_funnel_report`)

            if (fetchGroupId && fetchGroupId !== 'default') {
                initialGroupId = fetchGroupId
                setGroupId(initialGroupId)
            }
        }

        const data = {
            created_at: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            date: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            sort: sort === 'default' ? null : sort,
            group_id: initialGroupId || (groupId !== 'default' ? groupId : null),
        }

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/funnel-report`, { params: data })
            .then(res => res.data)
            .then((res: any) => {
                let items: any = []

                items[0] = {
                    id: 'all_users',
                    name: 'All Agents',
                    clusters: {
                        leads_report: res.all_users.total_leads,
                        closings_report: res.all_users.total_closings,
                        showings_plan: res.all_users.total_showings_plan,
                        showings_done_report: res.all_users.total_showings_done,
                        response_score: res.all_users.total_osa_report,
                        osa_score: res.all_users.total_osa_score,
                        response_time_score: res.all_users.total_response_time_score,
                        closing_utj_refund_report: 0,
                        closing_utj_non_refund_report: 0,
                        closing_agreed_sum: 0,
                        closing_agreed_count: 0,
                    }
                }

                if (res.users.length > 0) {
                    items = [
                        ...items,
                        ...res.users.map((user: any) => ({
                            id: user.id,
                            name: user.name,
                            clusters: {
                                leads_report: user.leads_report,
                                leads_transferred_report: user.leads_transferred_report,
                                closings_report: user.closings_report,
                                showings_plan: user.showings_plan_report,
                                showings_done_report: user.showings_done_report,
                                response_score: user.osa_report,
                                osa_score: user.osa_score,
                                response_time_score: user.response_time_score,
                                closing_utj_refund_report: user.closing_utj_refund_report,
                                closing_utj_non_refund_report: user.closing_utj_non_refund_report,
                                closing_agreed_sum: user.closing_agreed_sum,
                                closing_agreed_count: user.closing_agreed_count,
                            },
                            avatar_url: user.avatar_url
                        }))
                    ]
                }

                const hasMore = false

                setList(isFiltered ? items : list.concat(items))

                setFlags(prev => ({
                    ...prev,
                    isLoading: false,
                    isRefreshing: false,
                    isInit: false,
                    isChanged: false,
                    hasMore,
                }))

                setIsFirstInit(false)
            })
            .catch((err) => {
                generalErrorHandler(err)
            })
    }

    const ListHeaderComponent = (
        <>
            <div className={classes.selectsContainer}>
                <div>
                    <TextField
                        select
                        variant="outlined"
                        label="Sort"
                        name="sort"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                    >
                        <MenuItem value='default' defaultChecked>Default</MenuItem>
                        <MenuItem value='highest-osa-score'>Highest Agent Score</MenuItem>
                        <MenuItem value='highest-showing-rate'>Highest Showing Rate</MenuItem>
                        <MenuItem value='most-leads'>Most Leads</MenuItem>
                        <MenuItem value='most-closing'>Most Closing</MenuItem>
                        <MenuItem value="highest-utj-refund">Highest UTJ Refund</MenuItem>
                        <MenuItem value="highest-utj-non-refund">Highest UTJ Non Refund</MenuItem>
                        <MenuItem value="highest-closing-agreed-sum">Highest Akad</MenuItem>
                    </TextField>
                </div>
                {
                    permissions['report.filter-group'] &&
                    <div style={{ marginLeft: 'auto', minWidth: 100 }}>
                        <TextField
                            select
                            variant="outlined"
                            label="Group"
                            name="group_id"
                            value={groupId}
                            onChange={(e) => {
                                setGroupId(e.target.value)
                                localStorage.setItem(`selected_group_funnel_report`, e.target.value)
                            }}
                            fullWidth
                        >
                            <MenuItem value='default'>All</MenuItem>
                            {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}>{group.name}</MenuItem>)}
                        </TextField>
                    </div>
                }

            </div>
            <div className={classes.date}>
                <div style={{ marginLeft: 'auto' }} className={classes.datePickerContainer}>
                    <DateRangePicker
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onDatesChange={handleDateChanged}
                        format="DD/M/Y"
                        onFinished={() => fetchData(true)}
                        fullWidth
                    />
                </div>
            </div>
        </>
    )

    const getTitleByType = useMemo(() => {
        switch (selectedType) {
            case 'showing': return `Showing Done`
            case 'utj_refundable': return `UTJ Refundable`
            case 'utj_non_refundable': return `UTJ Non Refundable`
            case 'gmv_akad': return `GMV Akad (NoC)`
            default: return `-`
        }
    }, [selectedType])

    return (
        <Root>
            <FunnelReportClosingList
                title={getTitleByType}
                item={selectedClosing}
                type={selectedType}
                onClose={() => setSelectedClosing(null)}
                isMobile={isMobile}
                date={(state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null}
            />
            <div className={classes.root}>
                {ListHeaderComponent}
                <div className={classes.detailRoot}>
                    {
                        flags.isLoading ?
                            <>
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                            </>
                            :
                            list.length === 0 ?
                                <>
                                    <div />
                                    <DataNA
                                        type="leads"
                                        init={false}
                                        navigate={() => { }}
                                        renderBody={(titleStyle, subtitleStyle) =>
                                            <>
                                                <h5 style={titleStyle}>Maaf, report tidak ditemukan</h5>
                                                <p style={subtitleStyle}>Silahkan atur rentang tanggal</p>
                                            </>
                                        }
                                    />
                                </>
                                :
                                list.map(item =>
                                    <FunnelReportCard
                                        key={item.id}
                                        {...item}
                                        onClosingClick={(item, type) => {
                                            setSelectedClosing(item)
                                            setSelectedType(type)
                                        }}
                                    />  
                                )
                    }
                </div>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        // backgroundColor: '#DBEAFE4D',
        padding: '0px 16px 32px',
    },
    detailRoot: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: '20px',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#E9F0F5',
        padding: '6px 20px',
        borderRadius: 10,

        [theme.breakpoints.down('md')]: {
            marginBottom: 16,
        },
    },
    switchText: {
        fontSize: 18,
        lineHeight: 1,
        marginRight: 4,
        fontWeight: 500,
    },
    date: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '16px 8px 8px',
        marginBottom: 20,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    dateButton: {
        backgroundColor: "#EBF3F6",
    },
    dateText: {
        color: 'black',
        fontSize: 18,
    },
    footer_root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 16px',
        marginTop: 8,
    },
    footer_leads: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    footer_text: {
        fontSize: 26,
        color: 'black',
        fontWeight: 700,
    },
    footer_label: {
        fontSize: 18,
        color: 'black',
        marginLeft: 8,
    },
    table: {
        boxShadow: '0px 3px 8px #52575D30',
        backgroundColor: 'white',
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableLoading: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 130,
    },
    tableHeader: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    tableHeaderText: {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
    },
    tableFooterText: {
        fontWeight: 700,
        fontSize: 16,
        color: '#363636',
    },
    tableFooterTextLeft: {
        marginRight: 'auto',
    },
    cellText: {
        fontSize: 16,
        color: '#3D3D3D',
        padding: '17px 16px',
    },
    right: {
        textAlign: 'right',
    },
    cellWhite: {
        backgroundColor: 'white',
    },
    cellLightBlue: {
        backgroundColor: '#bcd6eb40',
    },
    cellDarkBlue: {
        backgroundColor: '#035aa633',
    },
    sortable: {
        transition: 'background-color 200ms linear',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& > .sort-icon': {
            marginLeft: 8,
            color: '#aaaaaa',

            '&.desc': {
                color: 'unset',
            },

            '&.asc': {
                color: 'unset',
                transform: 'rotate(180deg)',
            },
        },

        '&:hover': {
            backgroundColor: '#eeeeee',
        },
    },
    datePickerContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 220,
        }
    },
    selectsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
}), "Funnel_Report"
)

export default FunnelReport