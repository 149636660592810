import { Button, Theme } from '@mui/material';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
// import { useParams } from 'react-router-dom';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import { numberToCurrency } from '../../_utils/Helpers';

// Icons
import moment from 'moment';
import { PermissionContext } from '../../_contexts/PermissionContext';
import TransactionDetails from './TransactionDetails';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
}

export interface VerifyTransactionState {
    id: number,
    code: string,
    escrow_status: string,
    status: string,
    type: string,
    amount: number,
    proposed_by_name: string,
    approved_by_name: string,
    processed_by_name: string,
    released_by_name: string,
    created_at: string
}

export interface IEscrowTransaction {
    amount: number
    bank_behalf_name: string
    bank_data_id: 1
    bank_data_type: string
    bank_number: string
    bank_type_label: string
    code: string
    id: number
}

export interface IEscrowTransactionPropose {
    amount: number
    bank_type_label: string
    bank_id: number
    behalf_name: string
    label: string
    number: string
    type: string
}

export interface VerifyTransactionStateDetail {
    closing_id: number,
    escrow_status: string,
    escrow_transactions: IEscrowTransaction[]
    files: File[]
    id: number
    status: number
}

const VerifyTransactionList = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const API_URL = `${process.env.REACT_APP_API_URL}/closing-transaction`;
    const { Root, classes } = useStyles();
    // const params = useParams<IParams>();

    const [selectedTransaction, setSelectedTransaction] = useState<{ id: number | null, code: string }>(initialSeletedTransaction)

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'proposed_at',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY")}`
                )
            }
        },
        {
            name: 'code',
            label: 'Kode',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => (
                <span
                    className={classes.code}
                    onClick={() => {
                        setSelectedTransaction({ id: row.id, code: row.code })
                        window.history.pushState('', '', `/verify-transaction/${row.id}`)
                    }}
                >
                    {data ? data : '-'}
                </span>
            )
        },
        {
            name: 'escrow_status',
            label: 'Status',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...statusOptions.filter((item) => item.key !== 'canceled'),
            ],
            render: (data: any) => {
                let status = statusOptions.find(status => status.key === data)
                return status?.value || '-'
            }
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...(typeOptions),
            ],
            render: (data: any) => {
                let type = typeOptions.find(type => type.key === data)
                return type?.value || '-'
            }
        },
        {
            name: 'amount',
            label: 'Amount',
            type: 'number',
            filterable: false,
            render: (data: string, row: any) => (
                numberToCurrency(+data, 'Rp')
            )
        },
        {
            name: 'proposed_by_name',
            label: 'Proposed By',
            type: 'string',
            filterable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'approved_by_name',
            label: 'Approved By',
            type: 'string',
            filterable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'processed_by_name',
            label: 'Processed By',
            type: 'string',
            filterable: true,
            render: (data: string) => data || '-'
        },
    ];

    const onExport = () => {
        renderWarningButton('Apakah anda yakin ingin meng-export closing transaction?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/confirm-processed`)
                        .then(() => {
                            Swal.fire({
                                title: 'Data Processed sukses ditandai untuk di Export',
                                icon: 'success',
                            })
                            setReDrawDatatable(new Date().getTime())
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            });
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <TransactionDetails
                id={selectedTransaction.id}
                code={selectedTransaction.code}
                isOpen={!!selectedTransaction.id}
                onClose={(refresh: boolean) => {
                    setSelectedTransaction(initialSeletedTransaction)
                    window.history.pushState('', '', `/verify-transaction`)

                    if (refresh) {
                        setReDrawDatatable(new Date().getTime())
                    }
                }}
                permissions={permissions}
            />

            {
                permissions['closing-transaction.export'] &&
                <div className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onExport}
                    >
                        Export Transaksi dengan status Processed
                    </Button>
                </div>
            }

            <DataTable
                url={`${process.env.REACT_APP_API_URL}/closing-transaction`}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default VerifyTransactionList;

const initialSeletedTransaction = {
    id: null,
    code: ''
}

const statusOptions = [
    { key: 'proposed', value: 'Proposed', },
    { key: 'approved', value: 'Approved', },
    { key: 'processed', value: 'Processed', },
    { key: 'exported', value: 'Exported', },
    { key: 'released', value: 'Released', },
    { key: 'canceled', value: 'Canceled', },
]

const typeOptions = [
    { key: 'closing_fee', value: 'Closing Fee' },
    { key: 'komisi_bank', value: 'Komisi Bank' },
    { key: 'komisi_developer', value: 'Komisi Developer' },
    { key: 'other1', value: 'Komisi Lainnya 1' },
    { key: 'other2', value: 'Komisi Lainnya 2' },
]


const useStyles = generateStyle((theme: Theme) => ({
        code: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            transition: '150ms color linear',

            '&:hover': {
                color: theme.palette.primary.dark,
            }
        },
        label: {
            fontWeight: 'bold',
            marginBottom: 8,
            fontSize: 16,
        },
        value: {
            fontSize: 16,
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 16,
        }
    }), "VerifyTransaction_List"
);