import React, { useEffect, useState, useContext, ReactElement } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    Theme,
    Typography,
    IconButton
} from '@mui/material';

/**
 * Components
*/
import LoadingScreen from '../../_components/LoadingScreen';
import WhatsappPreviewModal, { IChats } from './_components/WhatsappPreviewModal';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { format } from 'date-fns'
import { generalErrorHandler } from '../../_utils/Helper';

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import { ChevronLeft, Handshake } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";
import { generateStyle } from '../../_utils/DefaultStyles';

interface Props {
    open: boolean;
    onClose: () => void;
    leadId: string;
    handleEdit: (id: string) => void;
    isMobile: boolean;
    fetchCount: number;
}

interface IActivity {
    agent_name: string,
    agent_id: string,
    additional_datas: string | null,
    client_name: string,
    client_phone: string,
    cluster_name: string,
    created_at: string,
    created_by_name: string,
    datetime: string,
    id: number,
    lead_id: number,
    notes: string,
    type: string,
    updated_at: string,
    user_id: null,
    other_agent_type: number
}

interface IState {
    id: string;
    code: string
    client_name: string;
    client_phone: string;
    client_email: string;
    status: string;
    status_label: string
    source: string;
    cluster_id: string;
    cluster_name: string;
    other_cluster_name: string
    cluster_has_placement: string
    agent_id: string;
    agent_name: string;
    notes: string;
    created_at: string
    activity: IActivity[]
    wa_id: string | null
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 16,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    container: {
        paddingBottom: '50px'
    },
    rightSection: {
        borderLeft: '1px solid #484848'
    },
    buttonStatus: {
        marginTop: 5,
        minWidth: 100
    },
    verticleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    verticleLine: {
        height: '100%',
        position: 'absolute',
        left: '50%',
        borderLeft: '1px solid #484848',
        zIndex: 1
    },
    timeline: {
        overflow: 'auto',
        borderTop: '1px solid lightgray',
        borderBottom: '1px solid lightgray',
        paddingTop: 12,
    },
    activityIcon: {
        width: 30,
        height: 30,
        zIndex: 2
    },
    activityIconBg: {
        borderRadius: 30,
        backgroundColor: '#7CC6FC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}), "Lead_Detail"
);

const LeadDetail = ({ open, onClose, leadId, handleEdit, isMobile, fetchCount }: Props) => {
    const { Root, classes } = useStyles();
    const permissions = useContext(PermissionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<IState>(initialState)
    const [chats, setChats] = useState<IChats[]>([])

    useEffect(() => {
        if (leadId) {
            setIsLoading(true);
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead/${leadId}`)
                .then(res => {
                    setState(res.data)
                })
                .catch(err => {
                    helpers.generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }, [open, leadId, fetchCount]);

    const handleWhatsappPreview = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead`, { params: { id: state.id } })
            .then(res => res.data)
            .then(data => setChats(data.data))
            .catch(err => generalErrorHandler(err))
    }

    const renderStatus = () => {
        let color = '#035AA6'

        if (state.status === 'booking_fee') {
            color = '#035AA6'
        } else if (state.status === 'showing') {
            color = '#343434'
        } else if (state.status === 'hot') {
            color = '#F56C6C'
        } else if (state.status === 'warm') {
            color = '#F0A456'
        } else if (state.status === 'cold') {
            color = '#00AEEF'
        } else if (state.status === 'excluded') {
            color = '#A6CE9E'
        } else {
            color = '#035AA6'
        }

        return (
            <Button
                variant="contained"
                className={classes.buttonStatus}
                style={{ backgroundColor: color }}
            >
                {state.status_label === 'undefined' ? (state.status === 'showing' ? 'Showing' : 'New') : state.status_label}
            </Button>
        )
    }

    const renderActivity = () => {
        let activity = [...state.activity]
        activity.reverse()

        const renderLine = (idx: number) => {
            return (
                <div className={classes.verticleLine}>
                </div>
            )
        }

        const renderTitle = (label: string) => {
            let text = ''
            switch (label) {
                case 'showing':
                    text = 'Showing'
                    break
                case 'call':
                    text = 'Phone Call'
                    break
                case 'whatsapp':
                    text = 'Message Via Whatsapp'
                    break
                case 'followup':
                    text = 'Follow Up'
                    break
                case 'closing':
                    text = 'Closing'
                    break
                default:
                    text = label
                    break
            }

            return text
        }

        const renderIcon = (type: string, agent_id: string) => {
            let url: any = null
            let muiIcon: ReactElement | null = null

            switch (type) {
                case 'showing':
                    if (agent_id !== state.agent_id) {
                        url = require('../../Assets/Images/leadAgentIcon/showing-alt.png')
                    } else {
                        url = require('../../Assets/Images/leadAgentIcon/showing.png')
                    }
                    break
                case 'call':
                    url = require('../../Assets/Images/leadAgentIcon/call.png')
                    break
                case 'whatsapp':
                    url = require('../../Assets/Images/leadAgentIcon/whatsapp.png')
                    break
                case 'followup':
                    url = require('../../Assets/Images/leadAgentIcon/showing-alt.png')
                    break
                case 'closing':
                    muiIcon = <Handshake htmlColor='white' fontSize='small' />
                    break
                default:
                    url = require('../../Assets/Images/leadAgentIcon/call.png')
                    break
            }

            if (muiIcon) {
                return (
                    <span className={`${classes.activityIcon} ${classes.activityIconBg}`}>
                        {muiIcon}
                    </span>
                )
            }

            return <img src={url} className={classes.activityIcon} alt='icon' />
        }

        return (
            activity.map((item, idx) => (
                <div key={idx}>
                    <Grid container sx={{ marginBottom: 1, paddingRight: 2 }}>
                        <Grid item xs={2} sx={{ position: 'relative' }}>
                            <div className={classes.verticleContainer}>
                                {renderIcon(item.type, item.agent_id)}
                            </div>
                            {renderLine(idx)}
                        </Grid>
                        <Grid item xs={10} sx={{ pl: 2 }}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="body2" >
                                        {
                                            format(new Date(), 'dd MMMM') === format(new Date(item.datetime), 'dd MMMM') ? 'Today' : format(new Date(item.datetime), 'dd MMMM')
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                        {
                                            format(new Date(item.created_at), 'hh:mm aaa')
                                        }
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant="subtitle2" >
                                        {renderTitle(item.type)} {item.type === 'showing' && `- ${item.cluster_name}`}
                                    </Typography>
                                    <Typography variant="body2" >
                                        {item.type === 'closing' ? 'Closing created' : item.notes}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            ))
        )
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            fullWidth
            maxWidth="md"
            fullScreen={isMobile}
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />

                <WhatsappPreviewModal
                    open={!!chats.length}
                    chats={chats}
                    onClose={() => setChats([])}
                    code={state.code || null}
                    created_at={state.created_at}
                />

                <DialogTitle className={classes.appBar}>
                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid>
                            <IconButton aria-label="back" onClick={() => onClose()} style={{ marginLeft: -8, marginRight: 2, color: '#fff' }}>
                                <ChevronLeft />
                            </IconButton>
                            {/* {state.client_name || ''} */}
                            <span>{state.code} - {state.client_name}</span>
                        </Grid>
                        <Grid>
                            {
                                (state.wa_id !== null && permissions['whatsapp.manage']) ?
                                    <IconButton aria-label="preview chat" title='Preview Chat' onClick={handleWhatsappPreview} style={{ color: '#fff' }}>
                                        <WhatsAppIcon />
                                    </IconButton>
                                    : null
                            }
                            <IconButton aria-label="edit" onClick={() => handleEdit(leadId)} style={{ color: '#fff' }} >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={7} sx={{ pr: isMobile ? 0 : 2 }}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" >
                                        Status
                                    </Typography>
                                    {renderStatus()}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" >
                                        Cluster
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        {state.cluster_name || state.other_cluster_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 1 }} >
                                        Agent
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                                        {state.agent_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 1 }} >
                                        Notes
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                                        {state.notes}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.rightSection}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }} >
                                Timeline
                            </Typography>
                            <div className={classes.timeline} style={{ maxHeight: !isMobile ? '275px' : 'unset' }}>
                                {renderActivity()}
                                <Grid container sx={{ marginBottom: 2 }}>
                                    <Grid item xs={2}>
                                        <div className={classes.verticleContainer}>
                                            <img src={require('../../Assets/Images/leadAgentIcon/add-contact.png')} className={classes.activityIcon} alt='icon' />
                                        </div>
                                    </Grid>
                                    <Grid item xs={10} sx={{ pl: 2 }}>
                                        <Grid container sx={{ paddingRight: 2 }}>
                                            <Grid item xs={8}>
                                                <Typography variant="body2" >
                                                    {
                                                        state.created_at ?
                                                            format(new Date(), 'dd MMMM') === format(new Date(state.created_at), 'dd MMMM') ? 'Today' : format(new Date(state.created_at), 'dd MMMM')
                                                            : ''
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                                    {state.created_at ? format(new Date(state.created_at), 'hh:mm aa') : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography variant="subtitle2" >
                                                    Client added to lead
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    );
}

const initialState = {
    id: '',
    code: '',
    client_name: '',
    client_phone: '',
    client_email: '',
    status: '',
    status_label: '',
    source: 'instagram-dm',
    cluster_id: '',
    cluster_name: '',
    other_cluster_name: '',
    cluster_has_placement: '1',
    agent_id: '',
    agent_name: '',
    notes: '',
    created_at: '',
    activity: [],
    wa_id: null
};

export default LeadDetail;