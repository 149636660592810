import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from './_components/PrivateRoute';
import MenuBar from './_components/MenuBar';
import './App.css';
import DefaultAxios from "./_utils/DefaultAxios";

/**
 * Context
 */
import { PermissionContext } from "../src/_contexts/PermissionContext";

/**
 * Defaults
 */
import Dashboard from './_pages/Dashboard';
import SignInSide from './_pages/SignInSide';

/**
 * Area
 */
import AreaForm from "./_pages/_area/AreaForm";
import AreaList from "./_pages/_area/AreaList";

/**
 * District
 */
import DistrictForm from "./_pages/_district/DistrictForm";
import DistrictList from "./_pages/_district/DistrictList";

/**
 * Kota Mandiri
 */
import KotaMandiriForm from "./_pages/_kotaMandiri/KotaMandiriForm";
import KotaMandiriList from "./_pages/_kotaMandiri/KotaMandiriList";

/**
 * Cluster Category
 */
import ClusterCategory from "./_pages/_clusterCategory/ClusterCategoryList";

/**
 * Cluster List
 */
import ClusterList from "./_pages/_cluster/ClusterList";
import ClusterForm from "./_pages/_cluster/ClusterForm";
import ModalDetailCluster from "./_pages/_cluster/ModalDetailCluster";

/**
 * Cluster Category
 */
import ClusterCategoryList from "./_pages/_clusterCategory/ClusterCategoryList";

/**
 * Kawasan
 */
import KawasanForm from "./_pages/_kawasan/KawasanForm";
import KawasanList from "./_pages/_kawasan/KawasanList";

/**
 * Project
 */
import ProjectForm from "./_pages/_project/ProjectForm";
import ProjectList from "./_pages/_project/ProjectList";

/**
 * Listing
 */
import ListingList from "./_pages/_listingList/ListingList";
import ListingForm from "./_pages/_listing/ListingForm";
import ModalDetailListing from "./_pages/_listing/ModalDetailListing";

/**
 * Site Setting
 */
import SiteSetting from "./_pages/_siteSetting/SiteSetting";

/**
 * Profile Setting
 */
import ProfileSetting from "./_pages/_profileSetting/ProfileSetting";

/**
 * Role
 */
import RoleList from "./_pages/_role/RoleList";

/**
 * User
 */
import UserList from "./_pages/_user/UserList";

/**
 * Force Login
 */
import ForceLogin from "./_pages/_user/ForceLogin";

/**
 * Asset Upload
 */
import AssetUploadList from "./_pages/_assetUpload/AssetUploadList";

/**
 * Article
 */
import ArticleForm from "./_pages/_article/_articleIndex/ArticleForm";
import ArticleList from "./_pages/_article/_articleIndex/ArticleList";
import CategoryForm from "./_pages/_article/_categoryIndex/CategoryForm";
import CategoryList from "./_pages/_article/_categoryIndex/CategoryList";
import ArticleNavbar from "./_pages/_article/_articleNavbar/ArticleNavbar";

/**
 * Lead
 */
import LeadList from "./_pages/_lead/LeadList";

/**
 * Activity
 */
import ActivityList from "./_pages/_activity/ActivityList";

/**
 * Closing
 */
import ClosingList from "./_pages/_closing/ClosingList";
import ClosingForm from "./_pages/_closing/ClosingForm";

/**
 * Report
 */
import LeadCounterReport from "./_pages/_leadCounterReport/LeadCounterReport";
import ClusterCounterReport from "./_pages/_clusterCounterReport/ClusterCounterReport";
import ClusterLeadReport from "./_pages/_clusterLeadReport/ClusterLeadReport";
import ClusterShowingReport from "./_pages/_clusterShowingReport/ClusterShowingReport";

/**
 * Contact
 */
import ContactList from "./_pages/_contact/ContactList";
import MobileAppRoute from "./_components/MobileAppRoute";

/**
 * Developer
 */
import DeveloperList from "./_pages/_developer/DeveloperList";

/**
 * PIC Lead Report
 */
import PicLeadReport from "./_pages/_picLeadReport/PicLeadReport";

/**
 * Login Logs
 */
import LogLogin from "./_pages/_logLogin/LogLogin";
import GenerateLeadsTest from "./_pages/_generateLeadsTest/GenerateLeadsTest";
import AgentList from "./_pages/_agent/AgentList";

/**
 * Featured Clusters
 */
import FeaturedClusters from "./_pages/_featuredClusters/FeaturedClusters";
import ArticleKPR from "./_pages/_article/_articleKPR/ArticleKPR";
import moment from "moment";
import UserGuide from "./_pages/_userGuide/UserGuideForm";
import LeadGroupList from "./_pages/_leadGroup/LeadGroupList";

/** 
 * Bank Interest
 */
import BankList from "./_pages/_bankInterest/BankList"
import BankForm from "./_pages/_bankInterest/BankForm";

/* 
* List Pinjaman
*/
import ListPinjaman from "./_pages/_listPinjaman/ListPinjaman";
import WhatsappPreview from "./_components/WhatsappPreview";

/* 
* System Broadcast
*/
// import FormBroadcast from "./_pages/_systemBroadcast/FormBroadcast";

/* 
* Consent Form
*/
import ConsentFormList from "./_pages/_consent/ConsentFormList";

/* 
* New Dashboard List 
*/
import NewDashboardList from "./_pages/_newDashboard/NewDashboardList";

import ReleaseAppList from "./_pages/_releaseApp/ReleaseAppList";

/**
 * Leads Report
 */
import LeadsReport from "./_pages/_reportScreen/LeadReport";

/**
 * Showing Report
 */
import ShowingReport from "./_pages/_reportScreen/ShowingReport";

/**
 * Funnel Report
 */
import FunnelReport from "./_pages/_reportScreen/FunnelReport";

/**
 * Agent Group
 */
import AgentGroupList from "./_pages/_agent/AgentGroupList";

/**
 * Sistem Escrow
 */
import StatementList from "./_pages/_statement/StatementList";
import VerifyTransactionList from "./_pages/_verifyTransaction/VerifyTransactionList";
import VerifyTransactionLoanList from "./_pages/_verifyTransactionLoan/VerifyTransactionLoanList";
import ClosingTransactionExport from "./_pages/_closingTransactionExport/ClosingTransactionExport";
import UserBankList from "./_pages/_userBank/UserBankList";

/**
 * Reimbursement
 */
import ReimbursementList from "./_pages/_sistemReimbursement/ReimbursementList";
import BlacklistSystemList from "./_pages/_systemBlacklist/BlacklistSystemList";

/**
 * Lead Pause Request
 */
import LeadPauseRequestList from "./_pages/_leadPauseRequest/LeadPauseRequestList";
import FormBroadcast from "./_pages/_systemBroadcast/FormBroadcast";
import FinanceTools from "./_pages/_financeTools/FinanceTools";
import TelesalesLeadGroupList from "./_pages/_telesalesLeadGroup/TelesalesLeadGroupList";
import SlpIconList from "./_pages/_slpIcon/SLPIconList";

import DashboardAds from "./_pages/_dashboardAds/DashboardAds";
import URLPlacementList from "./_pages/_urlPlacement/URLPlacementList";
import WhatsappChatChecker from "./_pages/_whatsappChatChecker/WhatsappChatChecker";
import CSChatsList from "./_pages/_csChats/CSChatsList";
import EnquiryReport from "./_pages/_reportScreen/EnquiryReport";
import PICUserGroupList from "./_pages/_picUserGroup/PicUserGroupList";
import LeadDirectsList from "./_pages/_leadDirects/LeadDirectsList";
import FacebookInstagramTokenList from "./_pages/_fbInstagramToken/FacebookInstagramTokenList";

const App = () => {
  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('permissions') || "{}"));

  useEffect(() => {
    if (localStorage.getItem("jwt_token") !== null) {
      if (localStorage.getItem("permissions") === null || moment().unix() > Number(localStorage.getItem('reset_permission_timer'))) {
        fetchPermissions();
      }
    }
  }, [permissions]);

  const fetchPermissions = () => {
    DefaultAxios
      .get(`${process.env.REACT_APP_API_URL}/permissions`)
      .then(res => {
        setPermissions(res.data || {});
        localStorage.setItem('permissions', JSON.stringify(res.data || {}));
        localStorage.setItem('reset_permission_timer', String(moment().add(15, 'minutes').unix()));
      });
  }

  return (
    <PermissionContext.Provider value={permissions}>
      <Router>
        <Routes>
          <Route path="/login" element={<SignInSide></SignInSide>} />
          <Route path="/" element={<PrivateRoute component={() => <MenuBar title="Dashboard"><Dashboard /></MenuBar>} key={"/"} />} />
          <Route path="/dashboard-app" element={<PrivateRoute component={() => <div style={{ padding: 8 }}><Dashboard /></div>} key={"/dashboard-app"} />} />

          {/* Force Login */}
          <Route path="/user/force" element={<PrivateRoute component={() => <ForceLogin />} key={"/user/force"} />} />

          <Route path="/project/add" element={<PrivateRoute component={() => <MenuBar title="Project Form"><ProjectForm /></MenuBar>} key={"/project/add"} />} />
          <Route path="/project/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Project Form"><ProjectForm /></MenuBar>} key={"/project/edit/:id/:type?"} />} />
          <Route path="/project" element={<PrivateRoute component={() => <MenuBar title="Project List"><ProjectList /></MenuBar>} key={"/project"} />} />

          {/* Unit */}
          <Route path="/listing/add" element={<PrivateRoute component={() => <MenuBar title="Add Unit" isGrayBackground><ListingForm /></MenuBar>} key={"/listing/add"} />} />
          <Route path="/listing/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Unit Form" isGrayBackground><ListingForm /></MenuBar>} key={"/listing/edit/:id/:type?"} />} />
          <Route path="/listing" element={<PrivateRoute component={() => <MenuBar title="Unit"><ListingList /></MenuBar>} key={"/listing"} />} />
          <Route path="/listing/detail/:id" element={<PrivateRoute component={() => <ModalDetailListing />} key={"/listing/detail/:id"} />} />

          {/* Area */}
          <Route path="/area/add" element={<PrivateRoute component={() => <MenuBar title="Area Form"><AreaForm /></MenuBar>} key={"/area/add"} />} />
          <Route path="/area/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Area Form"><AreaForm /></MenuBar>} key={"/area/edit/:id"} />} />
          <Route path="/area/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Area Form"><AreaForm /></MenuBar>} key={"/area/edit/:id/:type?"} />} />
          <Route path="/area" element={<PrivateRoute component={() => <MenuBar title="Area List"><AreaList /></MenuBar>} key={"/area"} />} />

          {/* District */}
          <Route path="/district/add" element={<PrivateRoute component={() => <MenuBar title="District Form"><DistrictForm /></MenuBar>} key={"/district/add"} />} />
          <Route path="/district/edit/:id" element={<PrivateRoute component={() => <MenuBar title="District Form"><DistrictForm /></MenuBar>} key={"/district/edit/:id"} />} />
          <Route path="/district/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="District Form"><DistrictForm /></MenuBar>} key={"/district/edit/:id/:type?"} />} />
          <Route path="/district" element={<PrivateRoute component={() => <MenuBar title="District List"><DistrictList /></MenuBar>} key={"/district"} />} />

          {/* Kota Mandiri */}
          <Route path="/mandiri/add" element={<PrivateRoute component={() => <MenuBar title="Kota Mandiri Form"><KotaMandiriForm /></MenuBar>} key={"/mandiri/add"} />} />
          <Route path="/mandiri/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Kota Mandiri Form"><KotaMandiriForm /></MenuBar>} key={"/mandiri/edit/:id"} />} />
          <Route path="/mandiri/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Kota Mandiri Form"><KotaMandiriForm /></MenuBar>} key={"/mandiri/edit/:id/:type?"} />} />
          <Route path="/mandiri" element={<PrivateRoute component={() => <MenuBar title="Mandiri List"><KotaMandiriList /></MenuBar>} key={"/mandiri"} />} />

          {/* Cluster Category */}
          <Route path="/cluster-category" element={<PrivateRoute component={() => <MenuBar title="Cluster Category"><ClusterCategory /></MenuBar>} key={"/cluster-category"} />} />
          <Route path="/cluster" element={<PrivateRoute component={() => <MenuBar title="Cluster List"><ClusterList /></MenuBar>} key={"/cluster"} />} />
          <Route path="/cluster-for-mobile" element={<PrivateRoute component={() => <MenuBar withoutAppBar withoutMainPadding title="Cluster List"><ClusterList isApp /></MenuBar>} key={"/cluster-for-mobile"} />} />
          <Route path="/cluster/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar isGrayBackground title="Cluster Form"><ClusterForm /></MenuBar>} key={"/cluster/edit/:id/:type?"} />} />
          <Route path="/cluster/add" element={<PrivateRoute component={() => <MenuBar title="Cluster Form" isGrayBackground><ClusterForm /></MenuBar>} key={"/cluster/add"} />} />
          <Route path="/cluster/detail/:id" element={<PrivateRoute component={() => <ModalDetailCluster />} key={"/cluster/detail/:id"} />} />
          <Route path="/cluster/category/:id" element={<PrivateRoute component={() => <ClusterCategoryList />} key={"/cluster/category/:id"} />} />

          {/* Kawasan */}
          <Route path="/kawasan/add" element={<PrivateRoute component={() => <MenuBar title="Kawasan Form"><KawasanForm /></MenuBar>} key={"/kawasan/add"} />} />
          <Route path="/kawasan/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Kawasan Form"><KawasanForm /></MenuBar>} key={"/kawasan/edit/:id"} />} />
          <Route path="/kawasan/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Kawasan Form"><KawasanForm /></MenuBar>} key={"/kawasan/edit/:id/:type?"} />} />
          <Route path="/kawasan" element={<PrivateRoute component={() => <MenuBar title="Kawasan List"><KawasanList /></MenuBar>} key={"/kawasan"} />} />

          {/* Site Setting */}
          <Route path="/site-setting" element={<PrivateRoute component={() => <MenuBar title="Site Setting"><SiteSetting /></MenuBar>} key={"/site-setting"} />} />

          {/* Profile Setting */}
          <Route path="/profile-setting" element={<PrivateRoute component={() => <MenuBar title="Profile Setting"><ProfileSetting /></MenuBar>} key={"/profile-setting"} />} />

          {/* Role */}
          <Route path="/role" element={<PrivateRoute component={() => <MenuBar title="Role List"><RoleList /></MenuBar>} key={"/role-list"} />} />

          {/* User */}
          <Route path="/user-list" element={<PrivateRoute component={() => <MenuBar title="User List"><UserList /></MenuBar>} key={"/user-list"} />} />
          <Route path="/user-list-for-mobile" element={<MobileAppRoute component={() => <MenuBar withoutAppBar title="User List"><UserList isApp /></MenuBar>} key={"/user-list-for-mobile"} />} />

          {/* Asset Upload */}
          <Route path="/asset-upload" element={<PrivateRoute component={() => <MenuBar title="Map File"><AssetUploadList /></MenuBar>} key={"/asset-upload"} />} />

          {/* Article */}
          <Route path="/article/add" element={<PrivateRoute component={() => <MenuBar title="Article Add Form"><ArticleForm /></MenuBar>} key={"/article/add"} />} />
          <Route path="/article-navbar" element={<PrivateRoute component={() => <MenuBar title="Article Navbar"><ArticleNavbar /></MenuBar>} key={"/article-navbar"} />} />
          <Route path="/article-kpr" element={<PrivateRoute component={() => <MenuBar title="Article KPR"><ArticleKPR /></MenuBar>} key={"/article-kpr"} />} />
          <Route path="/article/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Article Edit Form"><ArticleForm /></MenuBar>} key={"/article/edit/:id"} />} />
          <Route path="/article" element={<PrivateRoute component={() => <MenuBar title="Article Index"><ArticleList /></MenuBar>} key={"/article"} />} />
          <Route path="/category/add" element={<PrivateRoute component={() => <MenuBar title="Category Index Form"><CategoryForm /></MenuBar>} key={"/category/add"} />} />
          <Route path="/category" element={<PrivateRoute component={() => <MenuBar title="Category Index"><CategoryList /></MenuBar>} key={"/category"} />} />
          <Route path="/category/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Category Edit Form"><CategoryForm /></MenuBar>} key={"/category/edit/:id"} />} />

          {/* Lead */}
          <Route path="/lead" element={<PrivateRoute component={() => <MenuBar title="Lead List"><LeadList /></MenuBar>} key={"/lead"} />} />
          <Route path="/lead-for-mobile" element={<MobileAppRoute component={() => <MenuBar withoutAppBar withoutMainPadding title="Lead List"><LeadList isApp /></MenuBar>} key={"/lead-for-mobile"} />} />

          {/* Lead Unassigned */}
          <Route path="/lead-unassigned" element={<PrivateRoute component={function () { return <MenuBar title="Lead Unassigned List" ><LeadList is_unassigned /></MenuBar> }} key={"/lead"} />} />

          {/* Activity */}
          <Route path="/activity" element={<PrivateRoute component={function () { return <MenuBar title="Activity List" ><ActivityList /></MenuBar> }} key={"/activity"} />} />
          <Route path="/activity-for-mobile" element={<MobileAppRoute component={function () { return <MenuBar withoutAppBar title="Activity List" ><ActivityList isApp /></MenuBar> }} key={"/activity-for-mobile"} />} />

          {/* Closing */}
          <Route path="/closing/add" element={<PrivateRoute component={function () { return <MenuBar title="Closing Form" ><ClosingForm /></MenuBar> }} key={"/closing/add"} />} />
          <Route path="/closing/edit/:id" element={<PrivateRoute component={function () { return <MenuBar title="Closing Form" ><ClosingForm /></MenuBar> }} key={"/closing/edit/:id"} />} />
          <Route path="/closing/:id" element={<PrivateRoute component={function () { return <MenuBar title="Closing" ><ClosingList /></MenuBar> }} key={"/closing/:id"} />} />
          <Route path="/closing" element={<PrivateRoute component={function () { return <MenuBar title="Closing List" ><ClosingList /></MenuBar> }} key={"/closing"} />} />
          <Route path="/closing-for-mobile" element={<MobileAppRoute component={function () { return <MenuBar withoutAppBar title="Closing List" ><ClosingList /></MenuBar> }} key={"/closing-for-mobile"} />} />

          {/* Report */}
          <Route path="/pic-lead-counter-report" element={<PrivateRoute component={function () { return <MenuBar title="Counter Lead per PIC" ><LeadCounterReport /></MenuBar> }} key={"/pic-lead-counter-report"} />} />
          <Route path="/cluster-lead-counter-report" element={<PrivateRoute component={function () { return <MenuBar title="Counter Lead per Cluster" ><ClusterCounterReport /></MenuBar> }} key={"/cluster-lead-counter-report"} />} />

          {/* Report New */}
          <Route path="/pic-lead-counter-report/new" element={<PrivateRoute component={function () { return <MenuBar title="PIC Lead Counter Report (NEW)" ><LeadCounterReport is_new={true} /></MenuBar> }} key={"/pic-lead-counter-report"} />} />
          <Route path="/cluster-lead-report" element={<PrivateRoute component={function () { return <MenuBar title="Cluster Lead Report" ><ClusterLeadReport /></MenuBar> }} key={"/cluster-lead-report"} />} />
          <Route path="/cluster-showing-report" element={<PrivateRoute component={function () { return <MenuBar title="Cluster Showing Report" ><ClusterShowingReport /></MenuBar> }} key={"/cluster-showing-report"} />} />
          <Route path="/pic-lead-report" element={<PrivateRoute component={function () { return <MenuBar title="PIC Lead Report" ><PicLeadReport /></MenuBar> }} key={"/pic-lead-report"} />} />

          {/* Contact */}
          <Route path="/contact" element={<PrivateRoute component={function () { return <MenuBar title="Contact List" ><ContactList /></MenuBar> }} key={"/contact"} />} />

          {/* Reports */}
          <Route path="/leads-report" element={<PrivateRoute component={function () { return <MenuBar title="Leads Report" ><LeadsReport /></MenuBar> }} key={"/leads-report"} />} />
          <Route path="/leads-report/:group_id" element={<PrivateRoute component={function () { return <MenuBar title="Leads Report" ><LeadsReport /></MenuBar> }} key={"/leads-report"} />} />
          <Route path="/showing-report" element={<PrivateRoute component={function () { return <MenuBar title="Showing Report" ><ShowingReport /></MenuBar> }} key={"/showing-report"} />} />
          <Route path="/funnel-report" element={<PrivateRoute component={function () { return <MenuBar title="Funnel Report" ><FunnelReport /></MenuBar> }} key={"/funnel-report"} />} />
          <Route path="/enquiry-report" element={<PrivateRoute component={function () { return <MenuBar title="Leads Breakdown" ><EnquiryReport /></MenuBar> }} key={"/enquiry-report"} />} />
          <Route path="/enquiry-report-for-app" element={<PrivateRoute component={function () { return <MenuBar withoutAppBar title="Leads Breakdown" ><EnquiryReport /></MenuBar> }} key={"/enquiry-report-for-app"} />} />

          {/* Developer */}
          <Route path="/developer" element={<PrivateRoute component={function () { return <MenuBar title="Developer List" ><DeveloperList /></MenuBar> }} key={"/developer"} />} />

          {/* Bank Interest */}
          <Route path="/bank-interest" element={<PrivateRoute component={function () { return <MenuBar title="Bank Interest" ><BankList /></MenuBar> }} key={"/developer"} />} />
          <Route path="/bank-interest/add" element={<PrivateRoute component={function () { return <MenuBar title="Bank Interest" ><BankForm /></MenuBar> }} key={"/developer"} />} />
          <Route path="/bank-interest/edit/:id" element={<PrivateRoute component={function () { return <MenuBar title="Bank Interest" ><BankForm /></MenuBar> }} key={"/developer"} />} />

          {/* Log Login */}
          <Route path="/login-logs" element={<PrivateRoute component={function () { return <MenuBar title="Login Logs" ><LogLogin /></MenuBar> }} key={"/login-logs"} />} />
          <Route path="/featured-clusters" element={<PrivateRoute component={function () { return <MenuBar title="Featured Clusters" ><FeaturedClusters /></MenuBar> }} key={"/featured-clusters"} />} />

          {/* Temp Leads Test */}
          <Route path="/generate-leads-test" element={<PrivateRoute component={function () { return <MenuBar title="Generate Leads Test" ><GenerateLeadsTest /></MenuBar> }} key={"/generate-leads-test"} />} />

          {/* Agent */}
          <Route path="/agent-list" element={<PrivateRoute component={function () { return <MenuBar title="Agent List" ><AgentList /></MenuBar> }} key={"/agent-list"} />} />
          <Route path="/agent-list-for-mobile" element={<PrivateRoute component={function () { return <MenuBar withoutAppBar title="Agent List" ><AgentList isApp /></MenuBar> }} key={"/agent-list-for-mobile"} />} />

          {/* Agent Group */}
          <Route path="/agent-group" element={<PrivateRoute component={function () { return <MenuBar title="Agent Group" ><AgentGroupList /></MenuBar> }} key={"/agent-group"} />} />

          {/* User Guide */}
          <Route path="/user-guide" element={<PrivateRoute component={function () { return <MenuBar title="User Guide" ><UserGuide /></MenuBar> }} key={"/user-guide"} />} />

          {/* Lead Group */}
          <Route path="/lead-group" element={<PrivateRoute component={function () { return <MenuBar title="Cluster Lead Group" ><LeadGroupList /></MenuBar> }} key={"/lead-group"} />} />

          {/* List Pinjaman */}
          <Route path="/list-pinjaman" element={<PrivateRoute component={function () { return <MenuBar title="List Pinjaman" ><ListPinjaman /></MenuBar> }} key={"/list-pinjaman"} />} />

          {/* Consent Form */}
          <Route path="/consent-form" element={<PrivateRoute component={function () { return <MenuBar title="Consent Form" ><ConsentFormList /></MenuBar> }} key={"/consent-form"} />} />

          {/* Whatsapp Log Viewer */}
          <Route path="/whatsapp-preview" element={<PrivateRoute component={function () { return <MenuBar title="Whatsapp Preview" ><WhatsappPreview /></MenuBar> }} key={"/whatsapp-preview"} />} />

          {/* System Broadcast */}
          <Route path="/system-broadcast" element={<PrivateRoute component={function () { return <MenuBar title="System Broadcast" ><FormBroadcast /></MenuBar> }} key={"/lead-group"} />} />

          {/* New Dashboard */}
          <Route path="/new-dashboard" element={<PrivateRoute component={function () { return <MenuBar title="New Dashboard" ><NewDashboardList /></MenuBar> }} key={"/new-dashboard"} />} />

          {/* Sistem Reimbursement */}
          <Route path="/reimbursement" element={<PrivateRoute component={function () { return <MenuBar title="Reimbursement" ><ReimbursementList /></MenuBar> }} key={"reimbursement"} />} />
          <Route path="/reimbursement/:code" element={<PrivateRoute component={function () { return <MenuBar title="Reimbursement" ><ReimbursementList /></MenuBar> }} key={"reimbursement-detail"} />} />

          {/* Escrow */}
          <Route path="/statement" element={<PrivateRoute component={function () { return <MenuBar title="Statement List"><StatementList /></MenuBar> }} key={"/statement"} />} />
          <Route path="/verify-transaction/:id" element={<PrivateRoute component={function () { return <MenuBar title="Verify Transaction Komisi List"><VerifyTransactionList /></MenuBar> }} key={"/verify-closing"} />} />
          <Route path="/verify-transaction" element={<PrivateRoute component={function () { return <MenuBar title="Verify Transaction Komisi List"><VerifyTransactionList /></MenuBar> }} key={"/verify-closing"} />} />
          <Route path="/verify-transaction-loan" element={<PrivateRoute component={function () { return <MenuBar title="Verify Transaction Pinjaman List"><VerifyTransactionLoanList /></MenuBar> }} key={"/verify-loan"} />} />
          <Route path="/closing-transaction-export" element={<PrivateRoute component={function () { return <MenuBar title="Closing Transaction Export"><ClosingTransactionExport /></MenuBar> }} key="/closing-transaction-export" />} />
          <Route path="/user-bank" element={<PrivateRoute component={function () { return <MenuBar title="User Bank List"><UserBankList /></MenuBar> }} key="/user-bank" />} />

          {/* Sistem Blacklisted Phone */}
          <Route path="/blacklisted-phone" element={<PrivateRoute component={function () { return <MenuBar title="Blacklisted Phone" ><BlacklistSystemList /></MenuBar> }} key={"blacklisted-phone"} />} />

          {/* Lead Pause Request */}
          <Route path="/lead-pause-request" element={<PrivateRoute component={function () { return <MenuBar title="Lead Pause Request" ><LeadPauseRequestList /></MenuBar> }} key={"lead-pause-request"} />} />

          {/* Finance Tools */}
          <Route path="/finance-tools" element={<PrivateRoute component={function () { return <MenuBar title="Finance Tools" ><FinanceTools /></MenuBar> }} key={"finance-tools"} />} />

          {/* Telesales Lead Group */}
          <Route path="/telesales-lead-group" element={<PrivateRoute component={function () { return <MenuBar title="Telesales Lead Group" ><TelesalesLeadGroupList /></MenuBar> }} key={"telesales-lead-group"} />} />

          {/* SLP Icons */}
          <Route path="/slp-icons" element={<PrivateRoute component={function () { return <MenuBar title="Icon SLP" ><SlpIconList /></MenuBar> }} />} />

          {/* Dashboard Ads */}
          <Route path="/dashboard-ads" element={<PrivateRoute component={function () { return <MenuBar title="Performance Ads" ><DashboardAds /></MenuBar> }} key={"dashboard-ads"} />} />

          {/* Release App */}
          <Route path="/release-app" element={<PrivateRoute component={function () { return <MenuBar title="Release App" ><ReleaseAppList /></MenuBar> }} key={"/release-app"} />} />

          {/* URL Placement */}
          <Route path="/url-placement" element={<PrivateRoute component={function () { return <MenuBar title="URL Placement" ><URLPlacementList /></MenuBar> }} key={"/url-placement-list"} />} />

          {/* Whatsapp Chat Checker */}
          <Route path="/whatsapp-checker" element={<PrivateRoute component={function () { return <MenuBar title="Whatsapp Chat Checker" ><WhatsappChatChecker /></MenuBar> }} key={"/whatsapp-chat-checker"} />} />

          {/* Whatsapp Chat Checker */}
          <Route path="/cs-chats" element={<PrivateRoute component={function () { return <MenuBar title="CS Chats" ><CSChatsList /></MenuBar> }} key={"/cs-chats"} />} />

          {/* PIC User Group */}
          <Route path="/pic-user-group" element={<PrivateRoute component={function () { return <MenuBar title="PIC User Group" ><PICUserGroupList /></MenuBar> }} key={"/pic-user-group"} />} />

          {/* Lead Directs */}
          <Route path="/lead-directs" element={<PrivateRoute component={function () { return <MenuBar title="Lead Directs" ><LeadDirectsList /></MenuBar> }} key={"/lead-directs"} />} />

          {/* Facebook Instagram Token */}
          <Route path="/fb-instagram-token" element={<PrivateRoute component={function () { return <MenuBar title="FB Instagram Token" ><FacebookInstagramTokenList /></MenuBar> }} key={"/fb-instagram-token"} />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    </PermissionContext.Provider>
  )
}

export default App;