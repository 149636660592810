import { Paper, Table, TableBody, Tooltip, TableCell, TableContainer, TableHead, TableRow, Chip, IconButton, Dialog, DialogContent, Select } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FormOrder from './FormOrder';
import DatePickerModal from '../../../_components/_modals/DatePickerModal';
import GeneralFormModal, { GeneralFormState } from '../../../_components/_modals/GeneralFormModal';

/**
 * Utils
 */
import { numberToCurrency } from '../../../_utils/Helpers';
import { format } from "date-fns";

/**
 * Icons
 */
import NotesIcon from '@mui/icons-material/Notes';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

/**
 * Interfaces
 */
import { ITransaction } from './ClosingModalDetail';
import { transactionListOptionStatus } from '../_contants/TransactionListOption';
import { download, generalErrorHandler, renderSuccessButton, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

interface Props {
    closingId: string;
    data: any;
    confirmRefreshData?: () => void
    setLoading?: (loading: boolean) => void
    permissions?: any
}

const initialClosingState = {
    id: 0,
    transaction_id: 0,
    escrow_status: ''
}

interface ITransactionRender extends ITransaction {
    status_options: {
        label: string,
        value: string
    }[]
}

const TransactionList = (props: Props) => {
    const [code, setCode] = useState('')
    const [closing, setClosing] = useState<{
        id: number | string,
        transaction_id: number,
        escrow_status: string
    }>(initialClosingState)

    const [invoiceClosingTransactionId, setInvoiceClosingTransactionId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const [seeNotes, setSeeNotes] = useState('')
    const [cancelId, setCancelId] = useState<number | null>(null)

    const handleOpen = (data: ITransaction) => {
        setCode(data.code)
        setClosing({
            id: props.closingId,
            transaction_id: data.id,
            escrow_status: data.escrow_status
        })
        setOpen(true)
    }

    const handleClose = (refresh: boolean) => {
        setOpen(false)
        setClosing(initialClosingState)

        if (refresh) {
            props.confirmRefreshData && props.confirmRefreshData()
        }
    }

    const handleChangeStatus = (id: number, value: string) => {
        if (value === 'canceled') {
            setCancelId(id)
        } else {
            renderWarningButton('Apakah anda yakin ingin mengubah status pada transaksi ini ?')
                .then(res => {
                    if (res.value) {
                        props.setLoading && props.setLoading(true)
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction/change-status`, {
                            id: id,
                            status: value
                        })
                            .then(res => res.data)
                            .then(res => {
                                renderSuccessButton('Berhasil Ubah Status')
                                props.confirmRefreshData && props.confirmRefreshData()
                            })
                            .catch(generalErrorHandler)
                            .finally(() => {
                                props.setLoading && props.setLoading(false)
                            })
                    }
                })
        }
    }

    const renderNativeSelect = (status: string, option: ITransactionRender['status_options'], id: number) => {
        return (
            <Select
                key={status}
                value={status ? status : ''}
                native
                size='small'
                onChange={(e) => handleChangeStatus(id, e.target.value)}
                inputProps={{
                    name: 'status',
                    id: 'uncontrolled-native',
                }}
            >
                {
                    option?.length && option.map((option: any) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))
                }
            </Select>
        )
    }

    const renderTable = (title: string, data: ITransactionRender) => {
        return (
            <>
                <TableRow>
                    <TableCell>{title}</TableCell>
                    {
                        <TableCell>
                            {data.code ?
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    label={data.code}
                                    onClick={() => handleOpen(data)} />
                                :
                                "-"
                            }
                        </TableCell>
                    }
                    <TableCell>{data.estimated_date ? format(new Date(data.estimated_date), 'dd-MM-yyyy') : '-'}</TableCell>
                    <TableCell>{
                        data.estimated_nominal ? `Rp ${numberToCurrency(data.estimated_nominal)}` : '-'
                    } {
                            (props.permissions['closing.generate-invoice'] && data.estimated_nominal) ?
                                <IconButton title='Download Invoice Transaction' color='primary' onClick={() => { setInvoiceClosingTransactionId(data.id) }} size='small'>
                                    <PictureAsPdfIcon />
                                </IconButton> :
                                null
                        }</TableCell>
                    <TableCell>{data.tax_invoice_date ? format(new Date(data.tax_invoice_date), 'dd-MM-yyyy') : '-'}</TableCell>
                    <TableCell>{data.billed_nominal ? `Rp ${numberToCurrency(data.billed_nominal)}` : '-'}</TableCell>
                    <TableCell>{data.date ? format(new Date(data.date), 'dd-MM-yyyy') : '-'}</TableCell>
                    <TableCell>{data.nominal ? `Rp ${numberToCurrency(data.nominal)}` : '-'}</TableCell>
                    <TableCell>
                        {
                            data.status ?
                                props.permissions && props.permissions['closing-transaction.change-status'] ?
                                    <Tooltip
                                        disableTouchListener
                                        disableFocusListener
                                        enterDelay={200}
                                        enterNextDelay={200}
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        placement='top'
                                        title={
                                            data.status_options?.length ?
                                                data.status_options.find((option: any) => option.value === data.status)?.label :
                                                "-"
                                        }
                                    >
                                        {
                                            renderNativeSelect(data.status, data.status_options, data.id)
                                        }
                                    </Tooltip>
                                    :
                                    data.status_options?.length ?
                                        data.status_options.find((option: any) => option.value === data.status)?.label :
                                        "-"
                                : "-"
                        }
                    </TableCell>
                    <TableCell>
                        {
                            data.notes ?
                                <IconButton color='primary' onClick={() => setSeeNotes(data.notes)}>
                                    <NotesIcon />
                                </IconButton>
                                : "-"
                        }
                    </TableCell>
                </TableRow >
            </>
        )
    }

    const renderData = useMemo(() => {
        // if (props.data.length > 0) {
        // return props.data.map((data: any, key: number) => {
        return (
            <>
                {renderTable('Closing Fee', { ...props.data.closing_fee, status_options: transactionListOptionStatus['closing_fee'] })}
                {renderTable('Komisi Bank', { ...props.data.komisi_bank, status_options: transactionListOptionStatus['komisi_bank'] })}
                {renderTable('Komisi Developer', { ...props.data.komisi_developer, status_options: transactionListOptionStatus['komisi_developer'] })}
                {renderTable('Komisi Lainnya 1', { ...props.data.other1, status_options: transactionListOptionStatus['other1'] })}
                {renderTable('Komisi Lainnya 2', { ...props.data.other2, status_options: transactionListOptionStatus['other2'] })}
            </>
        )

        // eslint-disable-next-line
    }, [props.data])

    const handleSubmitInvoiceClosingTransaction = (date: Date) => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction/generate-invoice`, {
            id: invoiceClosingTransactionId,
            date: format(date, 'yyyy-MM-dd')
        }, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                download(url, 'Invoice-Closing-Transaction.pdf')
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
                setIsLoading(false)
                setInvoiceClosingTransactionId(0)
            })
    }

    const handleCancel = (state: GeneralFormState, onFinal: () => void) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction/change-status`, {
            id: cancelId,
            status: 'canceled',
            ...state,
            canceled_at: format(new Date(state.canceled_at as Date | string), 'yyyy-MM-dd')
        })
            .then(res => res.data)
            .then(res => {
                setCancelId(null)
                renderSuccessButton('Berhasil Ubah Status')
                props.confirmRefreshData && props.confirmRefreshData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                onFinal()
            })
    }

    return (
        <>
            {open && <FormOrder
                transactionId={closing.transaction_id}
                id={closing.id as number}
                open={open}
                code={code}
                status={closing.escrow_status}
                onClose={handleClose}
            />}

            <GeneralFormModal
                title='Batal Penjualan'
                open={cancelId !== null}
                onClose={() => {
                    setCancelId(null)
                }}
                onSubmit={handleCancel}
                forms={[
                    {
                        name: 'canceled_at',
                        type: 'datePicker',
                        defaultValue: null,
                        label: 'Tanggal',
                        required: true,
                        alias: 'Tanggal',
                    },
                    {
                        name: 'canceled_reason',
                        type: 'textArea',
                        defaultValue: '',
                        label: 'Alasan Cancel',
                        required: true,
                        alias: 'Alasan Cancel',
                    },
                ]}
            />

            <DatePickerModal
                isLoading={isLoading}
                open={!!invoiceClosingTransactionId}
                onClose={() => setInvoiceClosingTransactionId(0)}
                onSubmit={handleSubmitInvoiceClosingTransaction}

            />

            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Tipe Transaksi
                            </TableCell>
                            <TableCell>
                                Kode
                            </TableCell>
                            <TableCell>
                                Estimasi Pembayaran
                            </TableCell>
                            <TableCell>
                                Estimasi Nilai
                            </TableCell>
                            <TableCell>
                                Tanggal Faktur Pajak
                            </TableCell>
                            <TableCell>
                                Nilai Faktur Pajak
                            </TableCell>
                            <TableCell>
                                Tanggal Uang Masuk
                            </TableCell>
                            <TableCell>
                                Nilai Pembayaran
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                            <TableCell>
                                Notes
                            </TableCell>
                            {/* <TableCell>
                                Action
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderData}
                    </TableBody>
                </Table>
            </TableContainer>

            {seeNotes ?
                <Dialog open={!!seeNotes} onClose={() => setSeeNotes('')}>
                    <DialogContent>
                        {seeNotes}
                    </DialogContent>
                </Dialog>
                : null
            }
        </>
    );
}

export default TransactionList;