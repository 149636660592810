import React, { useReducer, useEffect, useState, useContext, } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import {
    Grid, Switch, Typography, Card, CardContent, TextField,
    Button, FormControlLabel, Collapse, Divider
} from '@mui/material';

/**
 * Components
 */
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import Swal from 'sweetalert2';
import FormCell from './_components/FormCell'

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';
import { inputNumber } from '../../_utils/Helper';
import helpers from '../../_utils/Helpers';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData, validateOne } from '../../_utils/Validation';
import TextArea from '../../_components/_form/TextArea';
import { generateStyle } from '../../_utils/DefaultStyles';

interface ITransactionType {
    is_shown: boolean
    tax_invoice_date: Date | null
    is_ppn: boolean
    is_pph23: boolean
    estimated_date: Date | null
    estimated_nominal: string
    billed_nominal: string
    date: Date | null
    nominal: string
}

interface ITransactionTypeOther extends ITransactionType {
    name: string
}

interface IState {
    code: string
    utj_date: Date | null
    sales_order_date: Date | null
    is_cobroke: boolean
    is_lead_personal: boolean
    lead_id: string
    lead_name: string
    agent_name: string
    commission_percentage: string
    cobroke_commission_percentage: string
    cluster_id: string
    cluster_name_label: string
    client_name: string
    unit_information: string
    estimated_transaction_amount: string
    transaction_amount: string
    payment_type: string
    client_phone: string
    client_email: string
    cobroke_agent_id: string
    cobroke_agent_name: string
    cobroke_percentage: string
    down_payment_nominal: string
    down_payment_installments: string
    payment_installments: string
    contract_agreement_date: Date | null
    payment_bank_id: string
    payment_bank_name: string
    // borrowed_commission_amount: string
    // borrowed_commission_transfer_date: Date | null
    // cobroke_borrowed_commission_amount: string
    // cobroke_borrowed_commission_transfer_date: Date | null
    payment_proof_file: any
    payment_proof_url: string
    sales_order_file: any
    sales_order_url: string
    transactions: {
        [key: string]: any
        closing_fee: {
            is_shown: boolean
            tax_invoice_date: Date | null
            is_ppn: boolean
            is_pph23: boolean
            estimated_date: Date | null
            estimated_nominal: string
            billed_nominal: string
            date: Date | null
            nominal: string
        },
        komisi_bank: {
            is_shown: boolean
            tax_invoice_date: Date | null
            is_ppn: boolean
            is_pph23: boolean
            estimated_date: Date | null
            estimated_nominal: string
            billed_nominal: string
            date: Date | null
            nominal: string
        },
        komisi_developer: ITransactionType,
        other1: ITransactionTypeOther,
        other2: ITransactionTypeOther,
    }
}

interface IErrorTransactions {
    [key: string]: any
    closing_fee: {
        is_ppn: string
        is_pph23: string
        estimated_date: string
        estimated_nominal: string
        date: string
        nominal: string
        notes: string
    },
    komisi_bank: {
        is_ppn: string
        is_pph23: string
        estimated_date: string
        estimated_nominal: string
        date: string
        nominal: string
        notes: string
    },
    komisi_developer: {
        is_pph23: string
        is_ppn: string
        estimated_date: string
        estimated_nominal: string
        date: string
        nominal: string
        notes: string
    },
    other1: {
        name: string
        is_ppn: string
        is_pph23: string
        estimated_date: string
        estimated_nominal: string
        date: string
        nominal: string
        notes: string
    },
    other2: {
        name: string
        is_ppn: string
        is_pph23: string
        estimated_date: string
        estimated_nominal: string
        date: string
        nominal: string
        notes: string
    },
}

interface ITransactionsData {
    is_ppn: boolean
    is_pph23: boolean
    estimated_date: Date | null
    estimated_nominal: string
    date: Date | null
    nominal: string
    notes: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface ITitle {
    size?: 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    type?: "small" | "sub" | "title"
    children: string
    noDivider?: boolean
}

export const useStyles = generateStyle(theme => ({
    root: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee',
        },
        '& .switch-form .Mui-disabled': {
            backgroundColor: 'unset',
        }
    },
    title: {
        fontSize: '34px',
        borderBottom: '1px solid #0000004D'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    swithTransactionContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subtitle: {
        fontSize: '20px'
    },
    smallSubtitle: {
        fontSize: '18px'
    },
    infoText: {
        color: '#000000',
        fontSize: '14px',
        opacity: '40%'
    },
    submitContainer: {
        marginTop: '25px'
    },
}), "ClosingForm"
);

const initialTransactionState = {
    is_shown: false,
    tax_invoice_date: null,
    is_ppn: false,
    is_pph23: false,
    estimated_date: null,
    estimated_nominal: '',
    billed_nominal: '',
    date: null,
    nominal: '',
}

const initialTransactionOtherState = { ...initialTransactionState, name: '' }

const initialState: IState = {
    code: "",
    utj_date: null,
    sales_order_date: null,
    is_cobroke: false,
    is_lead_personal: false,
    lead_id: '',
    lead_name: '',
    commission_percentage: '',
    cobroke_commission_percentage: '',
    agent_name: '',
    cluster_id: '',
    cluster_name_label: '',
    unit_information: '',
    estimated_transaction_amount: '',
    transaction_amount: '',
    payment_type: '',
    client_phone: '',
    client_email: '',
    client_name: '',
    cobroke_agent_id: '',
    cobroke_agent_name: '',
    cobroke_percentage: '',
    down_payment_nominal: '',
    down_payment_installments: '',
    payment_installments: '',
    payment_bank_id: '',
    payment_bank_name: '',
    contract_agreement_date: null,
    // borrowed_commission_amount: '',
    // borrowed_commission_transfer_date: null,
    // cobroke_borrowed_commission_amount: '',
    // cobroke_borrowed_commission_transfer_date: null,
    payment_proof_file: [],
    payment_proof_url: "",
    sales_order_file: [],
    sales_order_url: "",
    transactions: {
        closing_fee: { ...initialTransactionState },
        komisi_bank: { ...initialTransactionState },
        komisi_developer: { ...initialTransactionState },
        other1: { ...initialTransactionOtherState },
        other2: { ...initialTransactionOtherState },
    }
};

const ClosingForm = () => {
    const permissions = useContext(PermissionContext);
    const params: any = useParams();
    const navigate = useNavigate();
    const listTypes = [
        'closing_fee',
        'komisi_bank',
        'komisi_developer',
        'other1',
        'other2'
    ];

    const Title = (props: ITitle) => {
        let classType = classes.subtitle;

        if (props.type === 'small') {
            classType = classes.smallSubtitle;
        } else if (props.type === 'sub') {
            classType = classes.subtitle;
        } else {
            classType = classes.title;
        }

        return (
            <Grid item {...(props.size ? { xs: props.size } : '')}>
                <Typography className={classType}>
                    {props.children}
                </Typography>
                {!props.noDivider &&
                    <Divider variant="fullWidth" style={{ marginTop: "5px" }} />
                }
            </Grid>
        )
    }

    const [errorTransactions, setErrorTransactions] = useState<IErrorTransactions>({
        closing_fee: {
            is_ppn: '',
            is_pph23: '',
            estimated_date: '',
            estimated_nominal: '',
            date: '',
            nominal: '',
            notes: ''
        },
        komisi_bank: {
            is_ppn: '',
            is_pph23: '',
            estimated_date: '',
            estimated_nominal: '',
            date: '',
            nominal: '',
            notes: ''
        },
        komisi_developer: {
            is_ppn: '',
            is_pph23: '',
            estimated_date: '',
            estimated_nominal: '',
            date: '',
            nominal: '',
            notes: ''
        },
        other1: {
            name: '',
            is_ppn: '',
            is_pph23: '',
            estimated_date: '',
            estimated_nominal: '',
            date: '',
            nominal: '',
            notes: ''
        },
        other2: {
            name: '',
            is_ppn: '',
            is_pph23: '',
            estimated_date: '',
            estimated_nominal: '',
            date: '',
            nominal: '',
            notes: ''
        },
    })

    useEffect(() => {
        if (params.id) {
            setIsLoading(true)
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-admin/${params.id}`)
                .then(res => res.data)
                .then(data => {
                    if (data.status === 'cancel') {
                        navigate(`/closing/${params.id}`);
                    } else {
                        const newState = { ...inputState };
                        const newStateTransactions: any = { ...inputState.transactions };

                        newState.code = data.code;
                        newState.utj_date = data.utj_date || null;
                        newState.sales_order_date = data.sales_order_date || null;
                        newState.contract_agreement_date = data.contract_agreement_date || null;
                        newState.is_cobroke = data.is_cobroke === 1 ? true : false || false;
                        newState.is_lead_personal = data.is_lead_personal === 1 ? true : false || false;
                        newState.lead_id = data.lead_id || '';
                        newState.lead_name = data.lead_label || '';
                        // newState.agent_id = data.agent_id || '';
                        newState.commission_percentage = data.commission_percentage || '';
                        newState.cobroke_commission_percentage = data.cobroke_commission_percentage || '';
                        newState.agent_name = data.agent_name || '';
                        newState.cluster_id = data.cluster_id || '';
                        newState.cluster_name_label = data.cluster_name_label || '';
                        newState.unit_information = data.unit_information || '';
                        newState.estimated_transaction_amount = data.estimated_transaction_amount || '';
                        newState.transaction_amount = data.transaction_amount || '';
                        newState.payment_type = data.payment_type || '';
                        newState.client_phone = data.client_phone || '';
                        newState.client_email = data.client_email || '';
                        newState.client_name = data.client_name || '';
                        newState.cobroke_agent_id = data.cobroke_agent_id || '';
                        newState.cobroke_agent_name = data.cobroke_agent_name || '';
                        newState.cobroke_percentage = (Number(data.cobroke_percentage) * 100).toString() || '';
                        newState.down_payment_nominal = data.down_payment_nominal || '';
                        newState.down_payment_installments = data.down_payment_installments || '';
                        newState.payment_installments = data.payment_installments || '';
                        newState.payment_bank_id = data.payment_bank_id || '';
                        newState.payment_bank_name = data.payment_bank_name || '';
                        // newState.borrowed_commission_amount = data.borrowed_commission_amount || '';
                        // newState.borrowed_commission_transfer_date = data.borrowed_commission_transfer_date || null;
                        // newState.cobroke_borrowed_commission_amount = data.cobroke_borrowed_commission_amount || '';
                        // newState.cobroke_borrowed_commission_transfer_date = data.cobroke_borrowed_commission_transfer_date || null;

                        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });

                        if (data.transactions) {
                            for (let index = 0; index < listTypes.length; index++) {
                                const element = listTypes[index];
                                const dataTransactions = newStateTransactions[element];
                                const dataAPI = data.transactions[element];

                                if (dataAPI) {
                                    dataTransactions.estimated_date = dataAPI.estimated_date || null
                                    dataTransactions.estimated_nominal = dataAPI.estimated_nominal || ''
                                    dataTransactions.tax_invoice_date = dataAPI.tax_invoice_date || null
                                    dataTransactions.billed_nominal = dataAPI.billed_nominal || ''
                                    dataTransactions.is_ppn = dataAPI.is_ppn === 1 ? true : false || false;
                                    dataTransactions.is_pph23 = dataAPI.is_pph23 === 1 ? true : false || false;
                                    dataTransactions.date = dataAPI.date || null;
                                    dataTransactions.nominal = dataAPI.nominal || ''
                                    dataTransactions.name = dataAPI.name || ''
                                    dataTransactions.notes = dataAPI.notes || ''

                                    if (dataAPI.nominal || dataAPI.estimated_nominal || dataAPI.billed_nominal) {
                                        dataTransactions.is_shown = true;
                                    }
                                }
                            }

                            setInputState({ name: '', value: newStateTransactions, type: 'SET_ITEM' });
                        }

                    }
                })
                .catch(error => {
                    helpers.generalErrorHandler(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        // eslint-disable-next-line
    }, [params.id])

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [error, setError] = useState<IValidationErrors>({})
    const { Root, classes } = useStyles();
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [isLoading, setIsLoading] = useState(false);

    const rules: IValidationRules = {
        utj_date: params.id ? 'required' : '',
        // sales_order_date: 'required',
        lead_id: 'required',
        // agent_id: 'required',
        cluster_id: 'required',
        unit_information: 'required',
        transaction_amount: 'required',
        payment_type: 'required',
        client_name: 'required',
        cobroke_agent_id: 'required_if:is_cobroke=true',
        cobroke_percentage: 'required_if:is_cobroke=true',
        down_payment_installments: 'required_if:payment_type=kpr_reguler',
        payment_installments: 'required_if:payment_type=cash_bertahap',
        down_payment_nominal: inputState.payment_type === 'kpr_express' || inputState.payment_type === 'kpr_reguler' ? () => ({
            isValid: inputState.down_payment_nominal === '0' || inputState.down_payment_nominal === '0.00' || inputState.down_payment_nominal === '' ? false : true,
            errorMessage: 'Field DP is required',
        }) : '',
        estimated_nominal: 'required',
        labelOther1: 'required',
        labelOther2: inputState.transactions.other2.is_pph23 || inputState.transactions.other2.date || inputState.transactions.other2.nominal ||
            inputState.transactions.other2.estimated_date || inputState.transactions.other2.estimated_nominal ? 'required' : '',
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { errors, isValid } = validateOne(name, value, rules, alias)

        if (isValid) {
            setError(prev => ({
                ...prev,
                [name]: '',
            }))
        } else {
            setError(prev => ({
                ...prev,
                ...errors,
            }))
        }
    }

    const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value: any = e.target.value;

        const file = e.target.files;
        if (file && file[0]) {
            value = file[0];
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteLead = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.lead_id = value.id.toString();
        newState.client_name = value.client_name;
        newState.client_phone = value.client_phone;
        newState.agent_name = value.agent_name_label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;
        const isNumber = ['transaction_amount', 'down_payment_nominal', 'borrowed_commission_amount', 'cobroke_borrowed_commission_amount'].includes(name)
        if (name === 'client_phone') {
            value = helpers.convertIndonesiaPhoneNumber(value);
        }

        if (name === 'commission_percentage' || name === 'cobroke_commission_percentage') {
            value = helpers.maxDecimalDigit(value, 2);
        }

        setInputState({ name: name, value: isNumber ? inputNumber(value) : value, type: 'SET_ITEM' });
    }

    const handleChangeTransaction = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, param: string) => {
        const newState: any = { ...inputState.transactions };
        const target = e.target;
        let name = target.name;
        let value = target.value;
        const isNumber = ['estimated_nominal', 'nominal'].includes(name)

        newState[param][name] = value
        setInputState({ name: '', value: isNumber ? inputNumber(value) : value, type: 'SET_ITEM' });
    }

    const handleTransactionDateChange = (name: string, date: string | Date, param: string) => {
        const newState: any = { ...inputState.transactions };
        newState[param][name] = date
        setInputState({ name: '', value: date, type: 'SET_ITEM' });
    }

    const handlePPNSwitch = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
        const newState: any = { ...inputState.transactions };
        let value = event.target.checked;
        newState[param].is_ppn = value
        newState[param].is_pph23 = value

        setInputState({ name: '', value: value, type: 'SET_ITEM' });
    };

    const handleTaxSwitch = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
        const newState: any = { ...inputState.transactions };
        let value = event.target.checked;
        newState[param].is_pph23 = value

        setInputState({ name: '', value: value, type: 'SET_ITEM' });
    };

    const handleShowSwitch = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
        const newState: any = { ...inputState.transactions };
        let value = event.target.checked;
        newState[param].is_shown = value

        // Autofill estimasi nilai komisi bank on toggle
        if (param === 'komisi_bank' && value && newState[param].estimated_nominal === '' && inputState.down_payment_nominal !== '' && Number(inputNumber(inputState.down_payment_nominal)) > 0) {
            newState[param].estimated_nominal = helpers.convertNumber(Math.round(0.006 * Number(inputNumber(inputState.down_payment_nominal))).toString())
        }

        setInputState({ name: 'transactions', value: newState, type: 'SET_ITEM' });
    };

    const handleCobrokeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_cobroke = event.target.checked;

        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleLeadPersonal = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_lead_personal = event.target.checked;

        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleDateChange = (name: string, date: Date | null, value?: string | null | undefined) => {
        setInputState({ name: name, value: date, type: 'SET_ITEM' })
    }

    const validate = (key?: string, val?: string | boolean, silentError?: boolean) => {
        const message = "Form wajib diisi"
        let valid = true

        for (let index = 0; index < listTypes.length; index++) {
            const element = listTypes[index];
            const data = inputState.transactions[element];

            if ((data.date || data.nominal) && (!data.estimated_date || !data.estimated_nominal)) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        estimated_nominal: message,
                        estimated_date: message
                    }
                }))
            } else if (data.estimated_date && !data.estimated_nominal) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        estimated_nominal: message
                    }
                }))
            } else if (!data.estimated_date && data.estimated_nominal) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        estimated_date: message
                    }
                }))
            } else if (data.date && !data.nominal) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        nominal: message
                    }
                }))
            } else if (!data.date && data.nominal) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        date: message
                    }
                }))
            } else if ((element === 'other1' || element === 'other2') && (data.date || data.nominal || data.estimated_date || data.estimated_nominal) && !data.name) {
                valid = false
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        name: message
                    }
                }))
            } else {
                setErrorTransactions(prev => ({
                    ...prev,
                    [element]: {
                        [data]: ''
                    }
                }))
            }
        }

        return valid
    }

    const handleSubmit = () => {
        if (!params.id && !inputState.payment_proof_file.name) {
            Swal.fire({
                title: "Harap Upload Bukti Pembayaran",
                icon: 'error',
                timer: 1000
            })
        } else {
            const isValidTransaction = validate()
            const { errors, isValid } = validateData(inputState, rules, alias)
            setError(errors)
            if (isValid && isValidTransaction) {
                let transactionData: any = {}

                const listTypes = [
                    'closing_fee',
                    'komisi_bank',
                    'komisi_developer',
                    'other1',
                    'other2'
                ];

                for (let index = 0; index < listTypes.length; index++) {
                    const element = listTypes[index];

                    const data = inputState.transactions[element];

                    if ((data.date && data.nominal) || (data.estimated_date && data.estimated_nominal)) {
                        transactionData[element] = {
                            tax_invoice_date: data.tax_invoice_date ? helpers.dateFormat(data.tax_invoice_date, 'YYYY-MM-DD') : null,
                            is_pph23: params.id ? data.is_pph23 ? true : false : data.is_pph23 ? 1 : 0,
                            is_ppn: params.id ? data.is_ppn ? true : false : data.is_ppn ? 1 : 0,
                            estimated_date: data.estimated_date ? helpers.dateFormat(data.estimated_date, 'YYYY-MM-DD') : null,
                            estimated_nominal: data.estimated_nominal ? inputNumber(data.estimated_nominal.toString()) : null,
                            billed_nominal: data.billed_nominal ? inputNumber(data.billed_nominal.toString()) : null,
                            date: data.date ? helpers.dateFormat(data.date, 'YYYY-MM-DD') : null,
                            nominal: data.nominal ? inputNumber(data.nominal.toString()) : null,
                            name: data.name,
                            notes: data.notes
                        }
                    }
                }

                setIsLoading(true);

                const data = {
                    ...inputState,
                    is_cobroke: inputState.is_cobroke ? true : false,
                    is_lead_personal: inputState.is_lead_personal ? true : false,
                    cobroke_percentage: Number(inputState.cobroke_percentage) / 100,
                    utj_date: inputState.utj_date ? helpers.dateFormat(inputState.utj_date, 'YYYY-MM-DD') : null,
                    sales_order_date: inputState.sales_order_date ? helpers.dateFormat(inputState.sales_order_date, 'YYYY-MM-DD') : null,
                    contract_agreement_date: inputState.contract_agreement_date ? helpers.dateFormat(inputState.contract_agreement_date, 'YYYY-MM-DD') : null,
                    // borrowed_commission_transfer_date: inputState.borrowed_commission_transfer_date ? helpers.dateFormat(inputState.borrowed_commission_transfer_date, 'YYYY-MM-DD') : null,
                    // cobroke_borrowed_commission_transfer_date: inputState.cobroke_borrowed_commission_transfer_date ? helpers.dateFormat(inputState.cobroke_borrowed_commission_transfer_date, 'YYYY-MM-DD') : null,
                    transactions: transactionData
                }
                if (params.id) {
                    DefaultAxios.put(`${process.env.REACT_APP_API_URL}/closing-admin/${params.id}`, data)
                        .then(res => {
                            Swal.fire({
                                title: "Submit berhasil",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    navigate(`/closing/${params.id}`);
                                })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    const fd = new FormData();
                    for (let [key, value] of Object.entries(data)) {
                        if (value === null) {
                            fd.append(key, '');
                        } else if (key === 'transactions') {
                            for (let i in data.transactions) {
                                for (let key in data.transactions[i]) {
                                    let value = data.transactions[i][key as keyof ITransactionsData];
                                    if (value) {
                                        fd.append(`transactions[${i}][${key}]`, String(value));
                                    }
                                }
                            }
                        } else if (key === 'payment_proof_file') {
                            fd.append('payment_proof', value as File);
                        } else if (key === 'sales_order_file') {
                            fd.append('sales_order', value as File);
                        } else if (key === 'is_cobroke') {
                            fd.append('is_cobroke', value === true ? '1' : '0');
                        } else if (key === 'is_lead_personal') {
                            fd.append('is_lead_personal', value === true ? '1' : '0');
                        } else {
                            fd.append(key, value);
                        }
                    }

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-admin`, fd)
                        .then(res => {
                            Swal.fire({
                                title: "Submit berhasil",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    navigate(`/closing`);
                                })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            } else {
                console.log(errorTransactions)
                console.log('error validation')
            }
        }
    }

    const renderTransaction = (title: string, name: string) => {
        const newState: any = { ...inputState };

        return (
            <Grid style={{ marginTop: "24px" }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Title type={"sub"} noDivider={true}>{title}</Title>

                        <FormControlLabel
                            value="start"
                            control={<Switch
                                color="primary"
                                value={true}
                                checked={newState.transactions[name].is_shown ? newState.transactions[name].is_shown : false}
                                onChange={(e) => handleShowSwitch(e, name)}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />}
                            label=""
                            labelPlacement="start"
                        />
                    </Grid>
                    <Divider variant="fullWidth" />
                </Grid>

                <Collapse in={newState.transactions[name].is_shown} timeout="auto" unmountOnExit>
                    <Grid container spacing={3} style={{ marginTop: "8px" }}>
                        {
                            (name === 'other1' || name === 'other2') &&
                            <>
                                <Title type="small">Jenis Komisi</Title>
                                <FormCell
                                    label={`Jenis Komisi`}
                                    name={'name'}
                                    value={newState.transactions[name].name}
                                    error={errorTransactions[name].name}
                                    element={name}
                                    transactions
                                    handleBlur={handleBlur}
                                    handleChange={handleChangeTransaction}
                                />
                            </>
                        }

                        <Title type="small" noDivider={true}>Estimasi Pembayaran</Title>
                        <FormCell
                            type={'date'}
                            label={`Estimasi Tanggal Pembayaran ${title}`}
                            name={'estimated_date'}
                            value={newState.transactions[name].estimated_date}
                            error={errorTransactions[name].estimated_date}
                            element={name}
                            transactions
                            handleBlur={handleBlur}
                            handleChange={handleTransactionDateChange}
                        />
                        <FormCell
                            label={`Estimasi Nilai ${title}`}
                            name={'estimated_nominal'}
                            value={newState.transactions[name].estimated_nominal ? helpers.convertNumber(newState.transactions[name].estimated_nominal.toString()) : ''}
                            error={errorTransactions[name].estimated_nominal}
                            element={name}
                            transactions
                            handleBlur={handleBlur}
                            handleChange={handleChangeTransaction}
                        />

                        <Title type="small" noDivider={true}>Penagihan (Penerbitan Faktur Pajak)</Title>
                        <FormCell
                            type={'date'}
                            label={`Tanggal Faktur Penagihan ${title}`}
                            name={'tax_invoice_date'}
                            value={newState.transactions[name].tax_invoice_date}
                            element={name}
                            transactions
                            handleBlur={handleBlur}
                            handleChange={handleTransactionDateChange}
                        />
                        <FormCell
                            label={`Nilai Penagihan ${title}`}
                            name={'billed_nominal'}
                            value={newState.transactions[name].billed_nominal ? helpers.convertNumber(newState.transactions[name].billed_nominal.toString()) : ''}
                            error={errorTransactions[name].billed_nominal}
                            element={name}
                            transactions
                            handleBlur={handleBlur}
                            handleChange={handleChangeTransaction}
                        />

                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Title type="small">Pembayaran</Title>
                                <Grid item className='switch-form'>
                                    <FormControlLabel
                                        value="start"
                                        control={<Switch
                                            color="primary"
                                            value={true}
                                            checked={newState.transactions[name].is_ppn ? newState.transactions[name].is_ppn : false}
                                            onChange={(e) => handlePPNSwitch(e, name)}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />}
                                        label="PPN"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Switch
                                            color="primary"
                                            value={true}
                                            checked={newState.transactions[name].is_pph23 ? newState.transactions[name].is_pph23 : false}
                                            onChange={(e) => handleTaxSwitch(e, name)}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />}
                                        label="PPH23"
                                        labelPlacement="start"
                                        disabled={newState.transactions[name].is_ppn}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <FormCell
                            type={'date'}
                            label={`Tanggal Pembayaran ${title}`}
                            name={'date'}
                            value={newState.transactions[name].date}
                            error={errorTransactions[name].date}
                            element={name}
                            transactions
                            disabled={true}
                            handleBlur={handleBlur}
                            handleChange={handleTransactionDateChange}
                        />
                        <FormCell
                            label={`Nilai Pembayaran ${title}`}
                            name={'nominal'}
                            value={newState.transactions[name].nominal ? helpers.convertNumber(newState.transactions[name].nominal.toString()) : ''}
                            error={errorTransactions[name].nominal}
                            element={name}
                            disabled={true}
                            transactions
                            handleBlur={handleBlur}
                            handleChange={handleChangeTransaction}
                        />

                        <Grid item xs={12}>
                            <Title type="small">Notes</Title>
                            <TextArea
                                name='notes'
                                placeholder={(!newState.transactions[name].estimated_date || !newState.transactions[name].estimated_nominal) ? 'Harap isi estimasi pembayaran dan Estimasi Tanggal terlebih dahulu' : ''}
                                onChange={(e: any) => handleChangeTransaction(e, name)}
                                value={newState.transactions[name].notes}
                                error={errorTransactions[name].notes}
                                disabled={!newState.transactions[name].estimated_date || !newState.transactions[name].estimated_nominal}
                                // helperText={(!newState.transactions[name].estimated_date || !newState.transactions[name].estimated_nominal) ? 'Harap isi estimasi pembayaran dan Estimasi Tanggal terlebih dahulu' : ''}
                                // label='Notes'
                                rows={3}
                            />

                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        )
    }

    return (
        <Root>
            <Card className={classes.root}>
                <LoadingScreen open={isLoading} fullScreen />
                <CardContent style={{ maxWidth: '750px' }}>
                    <Grid container spacing={3}>
                        <Title size={12} type="title">{params.id ? `Edit Closing - ${inputState.code}` : 'Add Closing'}</Title>
                        <Grid item xs={12} className={classes.switchContainer}>
                            <Typography className={classes.subtitle}>
                                Cobroke
                                <br />
                                <Typography component="p" className={classes.infoText}>Aktifkan apabila transaksi ini transaksi cobroke</Typography>
                            </Typography>
                            <Switch
                                color="primary"
                                value={true}
                                checked={inputState.is_cobroke}
                                onChange={handleCobrokeSwitch}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>

                        <Title size={12} type="sub">Advisor</Title>
                        <FormCell
                            label={`Nama Advisor`}
                            name={'agent_name'}
                            value={inputState.agent_name}
                            disabled={true}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <FormCell
                            label={`Commission Percentage`}
                            name={'commission_percentage'}
                            value={inputState.commission_percentage}
                            endAdornment={"%"}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            disabled={!permissions['closing.change-commission-percentage-agent']}
                        />
                        {
                            inputState.is_cobroke &&
                            <>
                                <FormCell
                                    type={'autocomplete'}
                                    label={`Partner Cobroke`}
                                    name={'cobroke_agent_id'}
                                    value={inputState.cobroke_agent_name}
                                    error={error.cobroke_agent_id}
                                    handleChange={handleAutocomplete}
                                    handleAutocompleteChanged={handleAutocompleteInputChanged}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user/agent`}
                                />
                                <FormCell
                                    label={`Cobroke Commission Percentage`}
                                    name={'cobroke_commission_percentage'}
                                    value={inputState.cobroke_commission_percentage}
                                    endAdornment={"%"}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    disabled={!permissions['closing.change-commission-percentage-agent']}
                                />
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        name="cobroke_percentage"
                                        select
                                        label="Split Percentage Cobroke"
                                        value={inputState.cobroke_percentage}
                                        fullWidth
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        error={!!error.cobroke_percentage}
                                        onBlur={handleBlur}
                                        helperText={error.cobroke_percentage}

                                    >
                                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                        <option aria-label="cobroke_percentage" value="30">30%</option>
                                        <option aria-label="cobroke_percentage" value="50">50%</option>
                                    </TextField>
                                </Grid>
                            </>
                        }

                        {/* ------------------ */}

                        <Title size={12} type="sub">Customer Detail</Title>
                        <Grid item xs={12} className={classes.switchContainer}>
                            <Typography className={classes.subtitle}>
                                Leads Pribadi
                                <br />
                                <Typography component="p" className={classes.infoText}>Aktifkan bila Leads didapat secara pribadi</Typography>
                            </Typography>
                            <Switch
                                color="primary"
                                value={true}
                                checked={inputState.is_lead_personal}
                                onChange={handleLeadPersonal}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>
                        <FormCell
                            type={'autocomplete'}
                            label={`Lead`}
                            name={'lead_id'}
                            value={inputState.lead_name}
                            error={error.lead_id}
                            handleChange={handleAutocompleteLead}
                            handleAutocompleteChanged={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/lead`}
                        />
                        <FormCell
                            label={`Nama Lengkap Client (sesuai SP)`}
                            name={'client_name'}
                            value={inputState.client_name}
                            error={error.client_name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <FormCell
                            label={`Nomor HP Client`}
                            name={'client_phone'}
                            value={inputState.client_phone}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <FormCell
                            label={`Email Client`}
                            name={'client_email'}
                            value={inputState.client_email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        {/* ------------------ */}

                        <Title size={12} type="sub">Transaction Detail</Title>
                        <FormCell
                            type={'date'}
                            label={`Tanggal UTJ`}
                            name={'utj_date'}
                            value={inputState.utj_date}
                            error={error.utj_date}
                            handleBlur={handleBlur}
                            handleChange={handleDateChange}
                        />
                        <FormCell
                            type={'date'}
                            label={`Tanggal SP`}
                            name={'sales_order_date'}
                            value={inputState.sales_order_date}
                            handleBlur={handleBlur}
                            handleChange={handleDateChange}
                        />
                        <FormCell
                            type={'date'}
                            label={`Tanggal Akad`}
                            name={'contract_agreement_date'}
                            value={inputState.contract_agreement_date}
                            handleBlur={handleBlur}
                            handleChange={handleDateChange}
                        />
                        <FormCell
                            type={'autocomplete'}
                            label={`Cluster`}
                            name={'cluster_id'}
                            value={inputState.cluster_name_label}
                            error={error.cluster_id}
                            handleChange={handleAutocomplete}
                            handleAutocompleteChanged={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster`}
                        />
                        <FormCell
                            label={`Blok / No Unit`}
                            name={'unit_information'}
                            value={inputState.unit_information}
                            error={error.unit_information}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-select-currency-native"
                                name="payment_type"
                                select
                                label="Cara Pembayaran"
                                value={inputState.payment_type}
                                fullWidth
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                error={!!error.payment_type}
                                onBlur={handleBlur}
                                helperText={error.payment_type}
                            >
                                <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                <option aria-label="payment_type" value="cash_keras">Cash Keras</option>
                                <option aria-label="payment_type" value="kpr_express">KPR Express</option>
                                <option aria-label="payment_type" value="kpr_reguler">KPR Reguler (DP Cicil)</option>
                                <option aria-label="payment_type" value="cash_bertahap">Cash Bertahap Developer</option>
                            </TextField>
                        </Grid>

                        {
                            (inputState.payment_type === 'kpr_express' || inputState.payment_type === 'kpr_reguler') &&
                            <FormCell
                                label={`Nilai Financing`}
                                name={'down_payment_nominal'}
                                value={inputState.down_payment_nominal ? helpers.convertNumber(inputState.down_payment_nominal.toString()) : ''}
                                error={error.down_payment_nominal}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                startAdornment={'Rp. '}
                            />
                        }
                        {
                            inputState.payment_type === 'kpr_reguler' &&
                            <FormCell
                                label={`Cicilan DP`}
                                name={'down_payment_installments'}
                                value={inputState.down_payment_installments}
                                error={error.down_payment_installments}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                endAdornment={'x'}
                                inputType={'number'}
                            />
                        }
                        {
                            inputState.payment_type === 'cash_bertahap' &&
                            <FormCell
                                label={`Jumlah Angsuran (Cash Bertahap Developer)`}
                                name={'payment_installments'}
                                value={inputState.payment_installments ? helpers.convertNumber(inputState.payment_installments.toString()) : ''}
                                error={error.payment_installments}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                endAdornment={'x'}
                                inputType={'number'}
                            />
                        }
                        {(inputState.payment_type !== 'cash_keras' && inputState.payment_type !== 'cash_bertahap') &&
                            <FormCell
                                type={'autocomplete'}
                                label={`Bank`}
                                name={'payment_bank_id'}
                                value={inputState.payment_bank_name}
                                error={error.payment_bank_id}
                                handleChange={handleAutocomplete}
                                handleAutocompleteChanged={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/payment-bank`}
                            />
                        }
                        <FormCell
                            label={`Estimasi Nilai Transaksi`}
                            name={'estimated_transaction_amount'}
                            value={inputState.estimated_transaction_amount ? helpers.convertNumber(inputState.estimated_transaction_amount.toString()) : ''}
                            error={error.estimated_transaction_amount}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            startAdornment={'Rp. '}
                        />
                        <FormCell
                            label={`Nilai Transaksi (Inc Ppn.)`}
                            name={'transaction_amount'}
                            value={inputState.transaction_amount ? helpers.convertNumber(inputState.transaction_amount.toString()) : ''}
                            error={error.transaction_amount}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            startAdornment={'Rp. '}
                        />

                        {/* <Title size={12} type="sub">Pinjaman Komisi Advisor</Title> */}
                        {/* <FormCell
                        label={`Pinjaman Komisi Advisor`}
                        name={'borrowed_commission_amount'}
                        value={inputState.borrowed_commission_amount ? helpers.convertNumber(inputState.borrowed_commission_amount.toString()) : ''}
                        error={error.borrowed_commission_amount}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        startAdornment={'Rp. '}
                    />
                    <FormCell
                        type={'date'}
                        label={`Tanggal Pembayaran Pinjaman Komisi Advisor`}
                        name={'borrowed_commission_transfer_date'}
                        value={inputState.borrowed_commission_transfer_date}
                        handleBlur={handleBlur}
                        handleChange={handleDateChange}
                    /> */}
                        {/* {
                        inputState.is_cobroke &&
                        <>
                            <FormCell
                                label={`Pinjaman Komisi Cobroke`}
                                name={'cobroke_borrowed_commission_amount'}
                                value={inputState.cobroke_borrowed_commission_amount ? helpers.convertNumber(inputState.cobroke_borrowed_commission_amount.toString()) : ''}
                                error={error.cobroke_borrowed_commission_amount}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                startAdornment={'Rp. '}
                            />
                            <FormCell
                                type={'date'}
                                label={`Tanggal Pembayaran Pinjaman Komisi Cobroke`}
                                name={'cobroke_borrowed_commission_transfer_date'}
                                value={inputState.cobroke_borrowed_commission_transfer_date}
                                handleBlur={handleBlur}
                                handleChange={handleDateChange}
                            />
                        </>
                    } */}
                        {
                            !params.id &&
                            <>
                                <Grid item xs={12}>
                                    <Typography className={classes.subtitle}>
                                        Upload Bukti Pembayaran UTJ
                                    </Typography>
                                    <br></br>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="file"
                                        name="payment_proof_file"
                                        onChange={handleUploadFile}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography className={classes.subtitle}>
                                        Upload Surat Pemesanan (SP)
                                    </Typography>
                                    <br></br>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="file"
                                        name="sales_order_file"
                                        onChange={handleUploadFile}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>

                    {renderTransaction('Closing Fee', 'closing_fee')}
                    {renderTransaction('Komisi Bank', 'komisi_bank')}
                    {renderTransaction('Komisi Developer', 'komisi_developer')}
                    {renderTransaction('Komisi Lainnya 1', 'other1')}
                    {renderTransaction('Komisi Lainnya 2', 'other2')}

                    <Grid item xs={12} className={classes.submitContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}

const alias: IValidationAlias = {
    utj_date: 'Tanggal',
    sales_order_date: 'Tanggal',
    contract_agreement_date: 'Tanggal',
    lead_id: 'Lead',
    cluster_id: 'Cluster',
    unit_information: 'Informasi Unit',
    transaction_amount: 'Nilai Transaksi',
    payment_type: 'Cara Pembayaran',
    client_name: 'Nama Client',
    cobroke_agent_id: 'Partner Cobroke',
    cobroke_percentage: 'Percentage Cobroke',
    down_payment_nominal: 'Nilai Pinjaman Bank',
    payment_installments: 'Jumlah Angsuran',
    payment_bank_id: 'Bank',
    estimated_nominal: 'Estimasi Nominal',
    labelOther1: 'Label',
    labelOther2: 'Label',
}

export default ClosingForm;
