export const transactionListOptionStatus = {
    "closing_fee": [
        { "label": "Sudah Penagihan Dalam Proses Pelunasan", "value": "process_payment" },
        { "label": "Sudah Lunas", "value": "paid" },
        { "label": "Batal Penjualan", "value": "canceled" },
        { "label": "Tidak Ada Promo CF", "value": "no_closing_fee" }
    ],
    "komisi_developer": [
        { "label": "Belum Akad", "value": "not_confirmed" },
        { "label": "Sudah Akad Dalam Proses Penagihan", "value": "request_payment" },
        { "label": "Sudah Penagihan Dalam Proses Pelunasan", "value": "process_payment" },
        { "label": "Sudah Lunas", "value": "paid" },
        { "label": "Batal Penjualan", "value": "canceled" }
    ],
    "komisi_bank": [
        { "label": "Belum Akad", "value": "not_confirmed" },
        { "label": "Sudah Akad Dalam Proses Penagihan", "value": "request_payment" },
        { "label": "Sudah Penagihan Dalam Proses Pelunasan", "value": "process_payment" },
        { "label": "Sudah Lunas", "value": "paid" },
        { "label": "Batal Penjualan", "value": "canceled" },
        { "label": "Tidak Ada CB", "value": "no_commission_bank" }
    ],
    "other1": [
        { "label": "Sudah Penagihan Dalam Proses Pelunasan", "value": "process_payment" },
        { "label": "Sudah Lunas", "value": "paid" },
        { "label": "Batal Penjualan", "value": "canceled" }
    ],
    "other2": [
        { "label": "Sudah Penagihan Dalam Proses Pelunasan", "value": "process_payment" },
        { "label": "Sudah Lunas", "value": "paid" },
        { "label": "Batal Penjualan", "value": "canceled" }
    ]
}
